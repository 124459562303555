import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Components
import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";
// import SalesAnalytics from "./SalesAnalytics";
// import EarningReports from "./EarningReports";
// import Sources from "./Sources";
// import RecentlyActivity from "./RecentlyActivity";
// import RevenueByLocations from "./RevenueByLocations";
// import ChatBox from "./ChatBox";
// import LatestTransactions from "./LatestTransactions";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Sportzsquare", link: "/" },
                { title: "Dashboard", link: "#" },
            ],
            reports: [
                { icon: "r ri-user-line", title: "Total Player", value: "1452", rate: "2.4%", desc: "From previous period" },
                { icon: "ri-team-fill", title: "Total Team", value: "60", rate: "2.4%", desc: "From previous period" },
                { icon: "ri-live-line", title: "Live Match", value: "5", rate: "2.4%", desc: "From previous period" },
                { icon: " ri-history-line", title: "Upcoming Match", value: "30", rate: "2.4%", desc: "From previous period" },

            ]
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Dashboard" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col xl={12}>
                                <Row>
                                    <MiniWidgets reports={this.state.reports} />
                                </Row>
                                {/* revenue Analytics */}
                                <RevenueAnalytics />
                            </Col>

                            {/* <Col xl={4}> */}
                            {/* sales Analytics */}
                            {/* <SalesAnalytics /> */}

                            {/* earning reports */}
                            {/* <EarningReports />
                            </Col> */}
                        </Row>

                        {/* <Row> */}
                        {/* sources */}
                        {/* <Sources /> */}

                        {/* recent activity */}
                        {/* <RecentlyActivity /> */}

                        {/* revenue by locations */}
                        {/* <RevenueByLocations /> */}
                        {/* </Row> */}

                        {/* <Row> */}
                        {/* chat box */}
                        {/* <ChatBox /> */}

                        {/* latest transactions */}
                        {/* <LatestTransactions /> */}
                        {/* </Row> */}

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Dashboard;
