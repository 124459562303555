import { all } from 'redux-saga/effects'

//public
import accountSaga from './auth/register/saga';
import loginSaga from './auth/login/saga';
import forgetSaga from './auth/forgetpwd/saga';
import LayoutSaga from './layout/saga';

//Calendar 
import calendarSaga from "./calendar/saga";

//chat
import chatSaga from "./chat/saga"

//Ecommerce saga
import ecommerceSaga from "./e-commerce/saga";

//Continent saga
import ContinentSaga from "./location/Continent/saga";
import CountrySaga from "./location/Country/saga";
import StateSaga from './location/State/saga';
import CitySaga from './location/City/saga';
import VenueSaga from './location/Venue/saga';

import SportSaga from './Sports/saga';

import EventSaga from './Event/saga';

import SessionSaga from './Session/saga';

import TeamSaga from './Team/saga';

import ScheduleSaga from './Schedule/saga';

import ArticleSaga from './Article/saga';

import CategorySaga from './Category/saga';

import GroupSaga from './Group/saga';

import PlayerSaga from './Player/saga';

export default function* rootSaga() {
    yield all([
        //public
        accountSaga(),
        loginSaga(),
        forgetSaga(),
        LayoutSaga(),
        calendarSaga(),
        ecommerceSaga(),
        chatSaga(),
        ContinentSaga(),
        CountrySaga(),
        StateSaga(),
        CitySaga(),
        VenueSaga(),
        SportSaga(),
        EventSaga(),
        SessionSaga(),
        TeamSaga(),
        ScheduleSaga(),
        ArticleSaga(),
        CategorySaga(),
        GroupSaga(),
        PlayerSaga(),
    ])
}