import { GET_GROUP, LIST_GROUP, ADD_GROUP, EDIT_GROUP, DELETE_GROUPS, GET_GROUP_BY_Id, LIST_GROUP_BY_Id } from "./actionTypes";

export const getGroup = () => ({
    type: GET_GROUP,
});

export const listGroup = (event) => ({
    type: LIST_GROUP,
    payload: event,
});

export const getGroupById = (id) => ({
    type: GET_GROUP_BY_Id,
    payload: id,
});

export const listGroupById = (event) => ({
    type: LIST_GROUP_BY_Id,
    payload: event,
});

export const addGroup = (event) => ({
    type: ADD_GROUP,
    payload: event,
});

export const editGroup = (event, id) => ({
    type: EDIT_GROUP,
    payload: { event, id },
});

export const deleteGroups = (event) => ({
    type: DELETE_GROUPS,
    payload: event,
});
