import React, { useEffect, useMemo, useState } from "react";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Button,
    Label,
    Input,
} from "reactstrap";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import toastr from "toastr";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as Actions from "../../store/actions";
import Select from "react-select";

const TeamAction = (props) => {
    const { continent, country, event, session, sport } = props;
    const [breadcrumbItems] = useState([{ title: "Team", link: "#" }])
    const isEdit = useMemo(() => props?.location?.state?.editItem, [props])
    const [teamData, setTeamData] = useState({
        logo: '',
        name: '',
        type: '',
        category: '',
        sport: null,
        event: null,
        session: null,
        continent: null,
        country: null
    })

    useEffect(() => {
        props.getContinent();
        props.getEvent();
        props.getSport();

        if (props?.location?.state?.editItem) {
            console.log(props?.location?.state?.editItem, '---')
            if (props?.location?.state?.editItem?.continent?._id) {
                props.getCountryById(props.location.state.editItem.continent._id);
            }
            if (props?.location?.state?.editItem?.event?._id) {
                props.getSessionById(props.location.state.editItem.event._id);
            }
            setTeamData(prev => ({
                ...prev,
                // logo: props.location.state.editItem?.logo || '',
                name: props.location.state.editItem?.name || '',
                type: props.location.state.editItem?.type ? { name: props.location.state.editItem.type } : null || '',
                category: props.location.state.editItem?.category || '',
                sport: props.location.state.editItem?.sport || null,
                event: props.location.state.editItem?.event || null,
                session: props.location.state.editItem?.season || null,
                continent: props.location.state.editItem?.continent || null,
                country: props.location.state.editItem?.country || null,
            }))
        }

        return () => {
            props.clearAllSelect();
        };
    }, []);

    const handleInputChange = (name, value) => {
        console.log(value)
        setTeamData((Value) => ({ ...Value, [name]: value }));
        if (name === 'continent' && JSON.stringify(value) !== JSON.stringify(teamData.continent)) {
            props.getCountryById(value._id);
            handleInputChange('country', null);
        }
        if (name === 'event' && JSON.stringify(value) !== JSON.stringify(teamData.type)) {
            props.getSessionById(value._id);
            handleInputChange('session', null);
        }
    }

    const handleSubmit = async (e) => {
        if (!teamData.logo && !isEdit && toastr.warning("Please Enter Team Logo", "")) return;
        if (!teamData.name && toastr.warning("Please Enter Team Name", "")) return;
        if (!teamData.type && toastr.warning("Please Select Type", "")) return;
        if (!teamData.category && toastr.warning("Please Enter Category", "")) return;
        if (!teamData.event && toastr.warning("Please Select Event", "")) return;
        if (!teamData.session && toastr.warning("Please Select Session", "")) return;
        if (!teamData.sport && toastr.warning("Please Select Sport", "")) return;
        if (!teamData.continent && toastr.warning("Please Select Continent", "")) return;
        if (!teamData.country && toastr.warning("Please Select Country", "")) return;

        let formData = new FormData();
        if (!isEdit || teamData.logo) {
            formData.append('logo', teamData.logo);
        }
        formData.append('name', teamData.name);
        formData.append('category', teamData.category);
        formData.append('type', teamData.type.name);
        formData.append('event', teamData.event?._id);
        formData.append('season', teamData.session?._id);
        formData.append('sport', teamData.sport?._id);
        formData.append('continent', teamData.continent?._id);
        formData.append('country', teamData.country?._id);

        if (isEdit) {
            props.editTeam(formData, isEdit._id);
        } else {
            await props.addTeam(formData);
        }
        props.history.replace('/team')
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={!isEdit ? 'Add New Team' : 'Edit Team'} breadcrumbItems={breadcrumbItems} />
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label className="form-label">Team Logo</Label>
                                        <Input type="file" className="form-control" placeholder="Enter Pin Code" required accept="image/*" onChange={(e) => handleInputChange('logo', e.target.files[0])} />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label className="form-label">Team Name</Label>
                                        <Input type="text" className="form-control" placeholder="Enter Team Name" required value={teamData['name']} onChange={(e) => handleInputChange('name', e.target.value)} />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label className="form-label">Type</Label>
                                        <Select
                                            value={teamData['type']}
                                            onChange={(value) => handleInputChange('type', value)}
                                            options={[{ name: 'Mens' }, { name: 'Womans' }]}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.name}
                                            classNamePrefix="select2-selection"
                                            placeholder="Select Type"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label className="form-label">Category</Label>
                                        <Input type="text" className="form-control" placeholder="Enter Category" required value={teamData['category']} onChange={(e) => handleInputChange('category', e.target.value)} />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label className="form-label">Event</Label>
                                        <Select
                                            classNamePrefix="select2-selection"
                                            placeholder="Select Event"
                                            value={teamData['event']}
                                            onChange={(value) => handleInputChange('event', value)}
                                            options={event}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option?._id || option}
                                        />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label className="form-label">Sessions</Label>
                                        <Select
                                            classNamePrefix="select2-selection"
                                            placeholder="Select Sessions"
                                            value={teamData['session']}
                                            onChange={(value) => handleInputChange('session', value)}
                                            options={session}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option?._id || option}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label className="form-label">Sport</Label>
                                        <Select
                                            classNamePrefix="select2-selection"
                                            placeholder="Select Sport"
                                            value={teamData['sport']}
                                            onChange={(value) => handleInputChange('sport', value)}
                                            options={sport}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option?._id || option}
                                        />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label className="form-label">Continent</Label>
                                        <Select
                                            classNamePrefix="select2-selection"
                                            placeholder="Select Continent"
                                            value={teamData['continent']}
                                            onChange={(value) => handleInputChange('continent', value)}
                                            options={continent}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option?._id || option}
                                        />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label className="form-label">Country</Label>
                                        <Select
                                            classNamePrefix="select2-selection"
                                            placeholder="Select Country"
                                            value={teamData['country']}
                                            onChange={(value) => handleInputChange('country', value)}
                                            options={country}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option?._id || option}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <Button onClick={handleSubmit} color="success" className="waves-effect waves-light me-1 float-end">
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = ({ Continent, Country, Event, Sport, Session }) => {
    return {
        continent: Continent.continent,
        country: Country.countryById,
        event: Event.event,
        sport: Sport.sport,
        session: Session.sessionById,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamAction);