import { takeEvery, put, call } from "redux-saga/effects";
// Continent Redux States
import { GET_CITY, LIST_CITY, ADD_CITY, EDIT_CITY, DELETE_CITY, GET_CITY_BY_Id } from "./actionTypes";
import { listCity, listCityById } from "./actions";
import * as helpers from "../../../helpers/backend_Helper";
import toastr from "toastr";

function* onGetCity() {
    try {
        const response = yield call(helpers.getCity);
        yield put(listCity(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onGetCityById({ payload: id }) {
    try {
        const response = yield call(helpers.getCityById, id);
        yield put(listCityById(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onAddCity({ payload: event }) {
    try {
        const response = yield call(helpers.addCity, event);
        if (response.status === 1) {
            yield call(onGetCity);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onEditCity({ payload: { event, id } }) {
    try {
        const response = yield call(helpers.editCity, id, event);
        if (response.status === 1) {
            yield call(onGetCity);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onDeleteCity({ payload: event }) {
    try {
        const response = yield call(helpers.deleteCity, event);
        if (response.status === 1) {
            yield call(onGetCity);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* CitySaga() {
    yield takeEvery(GET_CITY, onGetCity);
    yield takeEvery(GET_CITY_BY_Id, onGetCityById);
    yield takeEvery(ADD_CITY, onAddCity);
    yield takeEvery(EDIT_CITY, onEditCity);
    yield takeEvery(DELETE_CITY, onDeleteCity);
}

export default CitySaga;
