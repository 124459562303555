import { takeEvery, put, call } from "redux-saga/effects";
// Continent Redux States
import { GET_SESSION, ADD_SESSION, EDIT_SESSION, DELETE_SESSION, GET_SESSION_BY_Id } from "./actionTypes";
import { listSession, listSessionById } from "./actions";
import * as helpers from "../../helpers/backend_Helper";
import toastr from "toastr";

function* onGetSession() {
    try {
        const response = yield call(helpers.getSession);
        yield put(listSession(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onGetSessionById({ payload: id }) {
    try {
        const response = yield call(helpers.getSessionById, id);
        yield put(listSessionById(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onAddSession({ payload: event }) {
    try {
        const response = yield call(helpers.addSession, event);
        if (response.status === 1) {
            yield call(onGetSession);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onEditSession({ payload: { event, id } }) {
    try {
        const response = yield call(helpers.editSession, id, event);
        if (response.status === 1) {
            yield call(onGetSession);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onDeleteSession({ payload: event }) {
    try {
        const response = yield call(helpers.deleteSession, event);
        if (response.status === 1) {
            yield call(onGetSession);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* SessionSaga() {
    yield takeEvery(GET_SESSION, onGetSession);
    yield takeEvery(GET_SESSION_BY_Id, onGetSessionById);
    yield takeEvery(ADD_SESSION, onAddSession);
    yield takeEvery(EDIT_SESSION, onEditSession);
    yield takeEvery(DELETE_SESSION, onDeleteSession);
}

export default SessionSaga;
