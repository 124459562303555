import { takeEvery, put, call } from "redux-saga/effects";
// Continent Redux States
import { GET_GROUP, ADD_GROUP, EDIT_GROUP, DELETE_GROUPS, GET_GROUP_BY_Id } from "./actionTypes";
import { listGroup, listGroupById } from "./actions";
import * as helpers from "../../helpers/backend_Helper";
import toastr from "toastr";

function* onGetGroup() {
    try {
        const response = yield call(helpers.getGroup);
        yield put(listGroup(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onGetGroupById({ payload: id }) {
    try {
        const response = yield call(helpers.getGroupById, id);
        yield put(listGroupById(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onAddGroup({ payload: event }) {
    try {
        const response = yield call(helpers.addGroup, event);
        if (response.status === 1) {
            yield call(onGetGroup);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onEditGroup({ payload: { event, id } }) {
    try {
        const response = yield call(helpers.editGroup, id, event);
        if (response.status === 1) {
            yield call(onGetGroup);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onDeleteGroup({ payload: event }) {
    try {
        const response = yield call(helpers.deleteGroups, event);
        if (response.status === 1) {
            yield call(onGetGroup);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* GroupSaga() {
    yield takeEvery(GET_GROUP, onGetGroup);
    yield takeEvery(GET_GROUP_BY_Id, onGetGroupById);
    yield takeEvery(ADD_GROUP, onAddGroup);
    yield takeEvery(EDIT_GROUP, onEditGroup);
    yield takeEvery(DELETE_GROUPS, onDeleteGroup);
}

export default GroupSaga;
