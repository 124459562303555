import { GET_CATEGORY, LIST_CATEGORY, ADD_CATEGORY, EDIT_CATEGORY, DELETE_CATEGORY, GET_CATEGORY_BY_Id, LIST_CATEGORY_BY_Id } from "./actionTypes";

export const getCategory = () => ({
    type: GET_CATEGORY,
});

export const listCategory = (event) => ({
    type: LIST_CATEGORY,
    payload: event,
});

export const getCategoryById = (id) => ({
    type: GET_CATEGORY_BY_Id,
    payload: id,
});

export const listCategoryById = (event) => ({
    type: LIST_CATEGORY_BY_Id,
    payload: event,
});

export const addCategory = (event) => ({
    type: ADD_CATEGORY,
    payload: event,
});

export const editCategory = (event, id) => ({
    type: EDIT_CATEGORY,
    payload: { event, id },
});

export const deleteCategory = (event) => ({
    type: DELETE_CATEGORY,
    payload: event,
});
