import { GET_CITY, LIST_CITY, ADD_CITY, EDIT_CITY, DELETE_CITY, GET_CITY_BY_Id, LIST_CITY_BY_Id } from "./actionTypes";

export const getCity = () => ({
    type: GET_CITY,
});

export const listCity = (event) => ({
    type: LIST_CITY,
    payload: event,
});

export const getCityById = (id) => ({
    type: GET_CITY_BY_Id,
    payload: id,
});

export const listCityById = (event) => ({
    type: LIST_CITY_BY_Id,
    payload: event,
});

export const addCity = (event) => ({
    type: ADD_CITY,
    payload: event,
});

export const editCity = (event, id) => ({
    type: EDIT_CITY,
    payload: { event, id },
});

export const deleteCity = (event) => ({
    type: DELETE_CITY,
    payload: event,
});
