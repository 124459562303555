import { takeEvery, put, call } from "redux-saga/effects";
// Continent Redux States
import { GET_TEAM, LIST_TEAM, ADD_TEAM, EDIT_TEAM, DELETE_TEAM, GET_TEAM_BY_Id } from "./actionTypes";
import { listTeam, listTeamById } from "./actions";
import * as helpers from "../../helpers/backend_Helper";
import toastr from "toastr";

function* onGetTeam() {
    try {
        const response = yield call(helpers.getTeam);
        yield put(listTeam(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onGetTeamById({ payload: id }) {
    try {
        const response = yield call(helpers.getTeamById, id);
        yield put(listTeamById(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onAddTeam({ payload: event }) {
    try {
        const response = yield call(helpers.addTeam, event);
        if (response.status === 1) {
            yield call(onGetTeam);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onEditTeam({ payload: { event, id } }) {
    try {
        const response = yield call(helpers.editTeam, id, event);
        if (response.status === 1) {
            yield call(onGetTeam);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onDeleteTeam({ payload: event }) {
    try {
        const response = yield call(helpers.deleteTeam, event);
        if (response.status === 1) {
            yield call(onGetTeam);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* TeamSaga() {
    yield takeEvery(GET_TEAM, onGetTeam);
    yield takeEvery(GET_TEAM_BY_Id, onGetTeamById);
    yield takeEvery(ADD_TEAM, onAddTeam);
    yield takeEvery(EDIT_TEAM, onEditTeam);
    yield takeEvery(DELETE_TEAM, onDeleteTeam);
}

export default TeamSaga;
