import { GET_ARTICLE, LIST_ARTICLE, ADD_ARTICLE, EDIT_ARTICLE, DELETE_ARTICLE, GET_ARTICLE_BY_Id, LIST_ARTICLE_BY_Id } from "./actionTypes";

export const getArticle = () => ({
    type: GET_ARTICLE,
});

export const listArticle = (event) => ({
    type: LIST_ARTICLE,
    payload: event,
});

export const getArticleById = (id) => ({
    type: GET_ARTICLE_BY_Id,
    payload: id,
});

export const listArticleById = (event) => ({
    type: LIST_ARTICLE_BY_Id,
    payload: event,
});

export const addArticle = (event) => ({
    type: ADD_ARTICLE,
    payload: event,
});

export const editArticle = (event, id) => ({
    type: EDIT_ARTICLE,
    payload: { event, id },
});

export const deleteArticle = (event) => ({
    type: DELETE_ARTICLE,
    payload: event,
});
