import React, { useEffect, useMemo, useState } from 'react';
import {
    Container,
    Row,
    Col,
    Input,
    Button,
    Card,
    Label,
    CardBody,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import * as Actions from "../../store/actions";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";

import toastr from "toastr";
import Select from "react-select";
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';

const ArticleAction = (props) => {
    const { player } = props
    const isEdit = useMemo(() => props?.location?.state?.editItem, [props])
    const [breadcrumbItems] = useState([{ title: "Blogs", link: "#" }])
    const [articleData, setArticleData] = useState({
        name: '',
        short_description: '',
        description: '',
        image: '',
        categories: [],
        player: null,
    });

    const handleInputChange = (name, value, isChecked) => {
        if (name == 'categories') {
            console.log(value)
            let newArray = isChecked ? [...articleData.categories, value] : articleData.categories.filter(e => e._id !== value._id)
            setArticleData((Value) => ({ ...Value, [name]: newArray }));
        } else {
            setArticleData((Value) => ({ ...Value, [name]: value }));
        }
    }

    const { quill, quillRef } = useQuill();
    useEffect(() => {
        if (quill) {
            quill.on('text-change', (delta, oldDelta, source) => {
                // console.log(quill.getText()); // Get text only
                // console.log(quill.getContents()); // Get delta contents
                // console.log(quill.root.innerHTML); // Get innerHTML using quill
                // console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
                handleInputChange('description', quill.root.innerHTML)
            });
            if (quill) {
                quill.clipboard.dangerouslyPasteHTML(articleData.description);
            }
        }
    }, [quill]);

    useEffect(() => {
        props.getCategory();
        props.getPlayer();
        console.log(props?.location?.state?.editItem, '---')
        if (props?.location?.state?.editItem) {
            console.log(props?.location?.state?.editItem, '---')
            setArticleData(prev => ({
                ...prev,
                name: props.location.state.editItem?.name,
                short_description: props.location.state.editItem?.short_description,
                description: props.location.state.editItem?.description,
                categories: props.location.state.editItem?.categories.map(data => data),
                player: props.location.state.editItem?.player?.[0],
            }))
        }
    }, [])

    const handleSubmit = async (e) => {
        if (!articleData.name && toastr.warning("Please Enter Blog Name", "")) return;
        if (!articleData.short_description && toastr.warning("Please Enter Blog Sort Description", "")) return;
        if (!articleData.description && toastr.warning("Please Enter Description", "")) return;
        if (!articleData.image && !isEdit && toastr.warning("Please Enter Image", "")) return;
        if (articleData.categories.length == 0 && toastr.warning("Please Select Category", "")) return;
        // if (!articleData.player && toastr.warning("Please Select Player", "")) return;

        let formData = new FormData();
        if (!isEdit || articleData.image) {
            formData.append('image', articleData.image);
        }
        formData.append('name', articleData?.name || '');
        formData.append('short_description', articleData?.short_description || '');
        formData.append('description', articleData?.description || '');
        if (articleData?.player?._id) {
            formData.append('player', articleData?.player?._id || null);
        }
        if (articleData.categories && articleData.categories.length > 0) {
            articleData.categories.forEach(data => {
                formData.append('categories', data?._id);
            })
        }

        if (isEdit) {
            props.editArticle(formData, isEdit._id);
        } else {
            await props.addArticle(formData);
        }
        props.history.replace('/article')
    };


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={!isEdit ? 'Add New Blogs' : 'Edit Blogs'}
                        breadcrumbItems={breadcrumbItems}
                    />

                    <Row>
                        <Col lg="9">
                            <Card>
                                <CardBody>
                                    <div className="mb-3">
                                        <Label className="form-label">Blogs Name</Label>
                                        <Input type="text" className="form-control" placeholder="Enter Blogs" required value={articleData['name']} onChange={(e) => handleInputChange('name', e.target.value)} />
                                    </div>
                                    <div className="mb-3">
                                        <Label className="form-label">Sort Description</Label>
                                        <Input type="text" className="form-control" placeholder="Enter Sort Description" required value={articleData['short_description']} onChange={(e) => handleInputChange('short_description', e.target.value)} />
                                    </div>
                                    <div className="mb-3">
                                        <Label className="form-label">Description</Label>
                                        <div ref={quillRef} />
                                    </div>

                                    <div className="mt-4 text-end">
                                        <Button onClick={handleSubmit} color="success" className="waves-effect waves-light me-1 float-end">
                                            Submit
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3">
                            <Card className="checkout-order-summary">
                                <CardBody>
                                    <div className="mb-3">
                                        <Label className="form-label">Image</Label>
                                        <Input type="file" className="form-control" placeholder="Enter Category Image" required accept="image/*" onChange={(e) => handleInputChange('image', e.target.files[0])} />
                                    </div>
                                    <div>
                                        <Label className="form-label">Category</Label>
                                        {props?.category.map((x, i) => (
                                            <div className="form-check mb-2" key={String(i)}>
                                                <Input className="form-check-input" type="checkbox" value="" id={`Category` + i} checked={articleData.categories.some(e => x._id == e._id)} onChange={(e) => handleInputChange('categories', x, e.target.checked)} />
                                                <Label className="form-check-label" htmlFor={`Category` + i}>{x?.name}</Label>
                                            </div>
                                        ))}
                                    </div>
                                </CardBody>
                            </Card>

                            <Card className="checkout-order-summary">
                                <CardBody>
                                    <Label className="form-label">Player</Label>
                                    <Select
                                        classNamePrefix="select2-selection"
                                        placeholder="Select Player"
                                        value={articleData['player']}
                                        onChange={(value) => handleInputChange('player', value)}
                                        options={player}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option?._id || option}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = ({ Category, Player, }) => {
    return {
        category: Category.category,
        player: Player.player,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleAction);