import { LIST_VENUE, LIST_VENUE_BY_Id } from "./actionTypes";

const INIT_STATE = {
    venue: [],
    venueById: [],
    error: {},
};

const Venue = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_VENUE:
            return {
                ...state,
                venue: action.payload,
            };
        case LIST_VENUE_BY_Id:
            return {
                ...state,
                venueById: action.payload,
            };
        default:
            return state;
    }
};

export default Venue;
