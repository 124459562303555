import { LIST_TEAM, LIST_TEAM_BY_Id } from "./actionTypes";

const INIT_STATE = {
    team: [],
    teamById: [],
    error: {},
};

const Team = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_TEAM:
            return {
                ...state,
                team: action.payload,
            };
        case LIST_TEAM_BY_Id:
            return {
                ...state,
                teamById: action.payload,
            };
        default:
            return state;
    }
};

export default Team;
