import React, { useEffect, useMemo, useState } from "react";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Button,
    Label,
    Input,
} from "reactstrap";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import toastr from "toastr";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as Actions from "../../store/actions";
import Select from "react-select";

const ArticleAction = (props) => {
    const { continent, country, state, city, team, playerCategory } = props;
    const [breadcrumbItems] = useState([{ title: "Player", link: "#" }])
    const isEdit = useMemo(() => props?.location?.state?.editItem, [props])
    const [teamData, setTeamData] = useState({
        image: '',
        name: '',
        playercategory: '',
        team: null,
        details: '',
        continent: null,
        country: null,
        state: null,
        city: null,
        address: '',
    })

    useEffect(() => {
        props.getPlayerCategory();
        props.getContinent();
        props.getTeam();

        if (props?.location?.state?.editItem) {
            console.log(props?.location?.state?.editItem, '---')
            if (props?.location?.state?.editItem?.continent?._id) {
                props.getCountryById(props.location.state.editItem.continent._id);
            }
            if (props?.location?.state?.editItem?.country?._id) {
                props.getStateById(props.location.state.editItem.country._id);
            }
            if (props?.location?.state?.editItem?.state?._id) {
                props.getCityById(props.location.state.editItem.state._id);
            }
            setTeamData(prev => ({
                ...prev,
                name: props.location.state.editItem?.name || '',
                playercategory: props.location.state.editItem?.playercategory || '',
                team: props.location.state.editItem?.team || null,
                details: props.location.state.editItem?.details || '',
                continent: props.location.state.editItem?.continent || null,
                country: props.location.state.editItem?.country || null,
                state: props.location.state.editItem?.state || null,
                city: props.location.state.editItem?.city || null,
                address: props.location.state.editItem?.address || null,
            }))
        }

        return () => {
            props.clearAllSelect();
        };
    }, []);

    const handleInputChange = (name, value) => {
        setTeamData((Value) => ({ ...Value, [name]: value }));
        if (name == 'continent') {
            if (JSON.stringify(value) !== JSON.stringify(teamData.continent)) {
                props.getCountryById(value._id);
                setTeamData((Value) => ({ ...Value, ['country']: null, ['state']: null, ['city']: null }));
            }
        } else if (name == 'country') {
            if (JSON.stringify(value) !== JSON.stringify(teamData.country)) {
                props.getStateById(value._id);
                setTeamData((Value) => ({ ...Value, ['state']: null, ['city']: null }));
            }
        } else if (name == 'state') {
            if (JSON.stringify(value) !== JSON.stringify(teamData.state)) {
                props.getCityById(value._id);
                setTeamData((Value) => ({ ...Value, ['city']: null }));
            }
        }
    }

    const handleSubmit = async (e) => {
        if (!teamData.image && !isEdit && toastr.warning("Please Enter Player Image", "")) return;
        if (!teamData.name && toastr.warning("Please Enter Player Name", "")) return;
        if (!teamData.playercategory && toastr.warning("Please Select Category", "")) return;
        if (!teamData.team && toastr.warning("Please Select Team", "")) return;
        if (!teamData.details && toastr.warning("Please Enter details", "")) return;
        if (!teamData.continent && toastr.warning("Please Select Continent", "")) return;
        if (!teamData.country && toastr.warning("Please Select Country", "")) return;
        if (!teamData.state && toastr.warning("Please Select State", "")) return;
        if (!teamData.city && toastr.warning("Please Select City", "")) return;
        if (!teamData.address && toastr.warning("Please Select Address", "")) return;

        let formData = new FormData();
        if (!isEdit || teamData.image) {
            formData.append('image', teamData.image);
        }
        formData.append('name', teamData.name);
        formData.append('playercategory', teamData.playercategory?._id);
        formData.append('team', teamData.team?._id);
        formData.append('details', teamData.details);
        formData.append('continent', teamData.continent?._id);
        formData.append('country', teamData.country?._id);
        formData.append('state', teamData.state?._id);
        formData.append('city', teamData.city?._id);
        formData.append('address', teamData.address);

        if (isEdit) {
            props.editPlayer(formData, isEdit._id);
        } else {
            await props.addPlayer(formData);
        }
        props.history.replace('/player')
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={!isEdit ? 'Add New Player' : 'Edit Player'} breadcrumbItems={breadcrumbItems} />
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label className="form-label">Player Image</Label>
                                        <Input type="file" className="form-control" placeholder="Enter Pin Code" required accept="image/*" onChange={(e) => handleInputChange('image', e.target.files[0])} />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label className="form-label">Player Name</Label>
                                        <Input type="text" className="form-control" placeholder="Enter Player Name" required value={teamData['name']} onChange={(e) => handleInputChange('name', e.target.value)} />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label className="form-label">Category</Label>
                                        <Select
                                            value={teamData['playercategory']}
                                            onChange={(value) => handleInputChange('playercategory', value)}
                                            options={playerCategory}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option?._id || option}
                                            classNamePrefix="select2-selection"
                                            placeholder="Select Type"
                                        />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label className="form-label">Team</Label>
                                        <Select
                                            classNamePrefix="select2-selection"
                                            placeholder="Select Team"
                                            value={teamData['team']}
                                            onChange={(value) => handleInputChange('team', value)}
                                            options={team}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option?._id || option}
                                        />
                                    </div>
                                </Col>
                                <Col lg={8}>
                                    <div className="mb-3">
                                        <Label className="form-label">Details</Label>
                                        <Input type="textarea" className="form-control" required value={teamData['details']} onChange={(e) => handleInputChange('details', e.target.value)} />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label className="form-label">Continent</Label>
                                        <Select
                                            classNamePrefix="select2-selection"
                                            placeholder="Select Continent"
                                            value={teamData['continent']}
                                            onChange={(value) => handleInputChange('continent', value)}
                                            options={continent}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option?._id || option}
                                        />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label className="form-label">Country</Label>
                                        <Select
                                            classNamePrefix="select2-selection"
                                            placeholder="Select Country"
                                            value={teamData['country']}
                                            onChange={(value) => handleInputChange('country', value)}
                                            options={country}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option?._id || option}
                                        />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label className="form-label">State</Label>
                                        <Select
                                            classNamePrefix="select2-selection"
                                            placeholder="Select State"
                                            value={teamData['state']}
                                            onChange={(value) => handleInputChange('state', value)}
                                            options={state}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option?._id || option}
                                        />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label className="form-label">City</Label>
                                        <Select
                                            classNamePrefix="select2-selection"
                                            placeholder="Select City"
                                            value={teamData['city']}
                                            onChange={(value) => handleInputChange('city', value)}
                                            options={city}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option?._id || option}
                                        />
                                    </div>
                                </Col>
                                <Col lg={8}>
                                    <div className="mb-3">
                                        <Label className="form-label">Address</Label>
                                        <Input type="textarea" className="form-control" required value={teamData['address']} onChange={(e) => handleInputChange('address', e.target.value)} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <Button onClick={handleSubmit} color="success" className="waves-effect waves-light me-1 float-end">
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = ({ Continent, Country, State, City, Team, Player }) => {
    return {
        continent: Continent.continent,
        country: Country.countryById,
        state: State.stateById,
        city: City.cityById,
        team: Team.team,
        playerCategory: Player.playerCategory,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleAction);