import { LIST_STATE, LIST_STATE_BY_Id } from "./actionTypes";

const INIT_STATE = {
    state: [],
    stateById: [],
    error: {},
};

const State = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_STATE:
            return {
                ...state,
                state: action.payload,
            };
        case LIST_STATE_BY_Id:
            return {
                ...state,
                stateById: action.payload,
            };
        case 'clearAllSelect':
            if (state?.stateById?.length > 0) {
                return {
                    ...state,
                    stateById: [],
                };
            }
        default:
            return state;
    }
};

export default State;
