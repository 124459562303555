import { GET_SCHEDULE, LIST_SCHEDULE, ADD_SCHEDULE, EDIT_SCHEDULE, DELETE_SCHEDULE, GET_SCHEDULE_BY_Id, LIST_SCHEDULE_BY_Id } from "./actionTypes";

export const getSchedule = () => ({
    type: GET_SCHEDULE,
});

export const listSchedule = (event) => ({
    type: LIST_SCHEDULE,
    payload: event,
});

export const getScheduleById = (id) => ({
    type: GET_SCHEDULE_BY_Id,
    payload: id,
});

export const listScheduleById = (event) => ({
    type: LIST_SCHEDULE_BY_Id,
    payload: event,
});

export const addSchedule = (event) => ({
    type: ADD_SCHEDULE,
    payload: event,
});

export const editSchedule = (event, id) => ({
    type: EDIT_SCHEDULE,
    payload: { event, id },
});

export const deleteSchedule = (event) => ({
    type: DELETE_SCHEDULE,
    payload: event,
});
