import { LIST_CATEGORY, LIST_CATEGORY_BY_Id } from "./actionTypes";

const INIT_STATE = {
    category: [],
    categoryById: [],
    error: {},
};

const Category = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_CATEGORY:
            return {
                ...state,
                category: action.payload,
            };
        case LIST_CATEGORY_BY_Id:
            return {
                ...state,
                categoryById: action.payload,
            };
        default:
            return state;
    }
};

export default Category;
