import { GET_SESSION, LIST_SESSION, ADD_SESSION, EDIT_SESSION, DELETE_SESSION, GET_SESSION_BY_Id, LIST_SESSION_BY_Id } from "./actionTypes";

export const getSession = () => ({
    type: GET_SESSION,
});

export const listSession = (event) => ({
    type: LIST_SESSION,
    payload: event,
});

export const getSessionById = (id) => ({
    type: GET_SESSION_BY_Id,
    payload: id,
});

export const listSessionById = (event) => ({
    type: LIST_SESSION_BY_Id,
    payload: event,
});

export const addSession = (event) => ({
    type: ADD_SESSION,
    payload: event,
});

export const editSession = (event, id) => ({
    type: EDIT_SESSION,
    payload: { event, id },
});

export const deleteSession = (event) => ({
    type: DELETE_SESSION,
    payload: event,
});
