import { LIST_CITY, LIST_CITY_BY_Id } from "./actionTypes";

const INIT_STATE = {
    city: [],
    cityById: [],
    error: {},
};

const City = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_CITY:
            return {
                ...state,
                city: action.payload,
            };
        case LIST_CITY_BY_Id:
            return {
                ...state,
                cityById: action.payload,
            };
        case 'clearAllSelect':
            if (state?.cityById?.length > 0) {
                return {
                    ...state,
                    cityById: [],
                };
            }
        default:
            return state;
    }
};

export default City;
