import { LIST_ARTICLE, LIST_ARTICLE_BY_Id, LIST_PLAYER } from "./actionTypes";

const INIT_STATE = {
    article: [],
    articleById: [],
    error: {},
};

const Article = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_ARTICLE:
            return {
                ...state,
                article: action.payload,
            };
        case LIST_ARTICLE_BY_Id:
            return {
                ...state,
                articleById: action.payload,
            };
        default:
            return state;
    }
};

export default Article;
