import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Table,
} from "reactstrap";
import { bindActionCreators } from 'redux';

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { API_URL_IMAGE } from "../../helpers/api_helper";
import * as Actions from "../../store/actions";
import DeleteModal from "../Tables/deleteModal";
// const API_URL = "http://192.168.31.131:3000/uploads/blog/"

const Article = (props) => {
    const [breadcrumbItems] = useState([{ title: "Blogs", link: "#" }]);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        props.getArticle();
    }, []);

    const handleDeleteEvent = () => {
        props.deleteArticle(selectedRow?._id || "");
        setIsDeleteModal(false);
        setSelectedRow(null)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Blogs" breadcrumbItems={breadcrumbItems} />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex  justify-content-between mb-2">
                                        <h4 className="card-title">Blogs List</h4>
                                        <Button onClick={() => props.history.push('/articleAction')} color="success" className="waves-effect waves-light me-1 float-end">
                                            Add New Blogs
                                        </Button>
                                    </div>
                                    <div className="table-responsive article-table">
                                        <Table className="table table-bordered table-hover">
                                            <thead className="table-light table-nowrap">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Blogs Image</th>
                                                    <th>Blogs Name</th>
                                                    <th>Category</th>
                                                    <th>Sort Description</th>
                                                    <th style={{ width: "100px" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.article.map((e, i) => {
                                                    return (
                                                        <tr key={String(i)}>
                                                            <th scope="row">{i + 1}</th>
                                                            {/* <td><img src={API_URL_IMAGE + 'blog/' + e?.image} /></td> */}
                                                            <td><img src={e?.image} alt="" /></td>
                                                            <td>{e?.name}</td>
                                                            <td>{e?.categories.map(data => data.name).join(', ')}</td>
                                                            <td>{e?.short_description}</td>
                                                            <td>
                                                                <Link to={{ pathname: '/articleAction', state: { editItem: e } }} className="me-3 text-primary">
                                                                    <i className="mdi mdi-pencil font-size-18"></i>
                                                                </Link>
                                                                <Link to="#" className="text-danger" onClick={() => { setIsDeleteModal(true); setSelectedRow(e) }}>
                                                                    <i className="mdi mdi-trash-can font-size-18"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                {/* <tr>
                                                    <th scope="row">1</th>
                                                    <td><img src={'https://media.istockphoto.com/id/813136942/photo/selective-focus-of-stacking-magazine-place-on-table-in-living-room.jpg?s=612x612&w=0&k=20&c=6nRlgDo9ecsb1vCPlN8G4cmq4vf8lW4YkSMhoU-jSqE='} height={'50px'} /></td>
                                                    <td>Best Game</td>
                                                    <td>Cricket</td>
                                                    <td>Shubman Gill Centuries | List of Shubman Gill international & IPL centuries</td>
                                                    <td>
                                                        <Link to="#" className="me-3 text-primary">
                                                            <i className="mdi mdi-pencil font-size-18"></i>
                                                        </Link>
                                                        <Link to="#" className="text-danger">
                                                            <i className="mdi mdi-trash-can font-size-18"></i>
                                                        </Link>
                                                    </td>
                                                </tr> */}
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <DeleteModal show={isDeleteModal} onDeleteClick={handleDeleteEvent} onCloseClick={() => setIsDeleteModal(false)} />
        </React.Fragment>
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = ({ Article }) => {
    return {
        article: Article.article,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Article);