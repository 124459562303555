import { takeEvery, put, call } from "redux-saga/effects";
// Continent Redux States
import { GET_PLAYER, ADD_PLAYER, EDIT_PLAYER, DELETE_PLAYER, GET_PLAYER_BY_Id, GET_PLAYER_CATEGORY, GET_PLAYER_CATEGORY_BY_Id, ADD_PLAYER_CATEGORY, EDIT_PLAYER_CATEGORY, DELETE_PLAYER_CATEGORY } from "./actionTypes";
import { listPlayer, listPlayerById, listPlayerCategory, listPlayerCategoryById } from "./actions";
import * as helpers from "../../helpers/backend_Helper";
import toastr from "toastr";

function* onGetPlayer() {
    try {
        const response = yield call(helpers.getPlayer);
        yield put(listPlayer(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onGetPlayerById({ payload: id }) {
    try {
        const response = yield call(helpers.getPlayeryId, id);
        yield put(listPlayerById(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onAddPlayer({ payload: event }) {
    try {
        const response = yield call(helpers.addPlayer, event);
        if (response.status === 1) {
            yield call(onGetPlayer);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onEditPlayer({ payload: { event, id } }) {
    try {
        const response = yield call(helpers.editPlayer, id, event);
        if (response.status === 1) {
            yield call(onGetPlayer);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onDeletePlayer({ payload: event }) {
    try {
        const response = yield call(helpers.deletePlayers, event);
        if (response.status === 1) {
            yield call(onGetPlayer);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

/* ========================================================================== */

function* onGetPlayerCategory() {
    try {
        const response = yield call(helpers.getPlayerCategory);
        yield put(listPlayerCategory(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onGetPlayerCategoryById({ payload: id }) {
    try {
        const response = yield call(helpers.getPlayerCategoryById, id);
        yield put(listPlayerCategoryById(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onAddPlayerCategory({ payload: event }) {
    try {
        const response = yield call(helpers.addPlayerCategory, event);
        if (response.status === 1) {
            yield call(onGetPlayerCategory);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onEditPlayerCategory({ payload: { event, id } }) {
    try {
        const response = yield call(helpers.editPlayerCategory, id, event);
        if (response.status === 1) {
            yield call(onGetPlayerCategory);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onDeletePlayerCategory({ payload: event }) {
    try {
        const response = yield call(helpers.deletePlayerCategory, event);
        if (response.status === 1) {
            yield call(onGetPlayerCategory);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* PlayerSaga() {
    yield takeEvery(GET_PLAYER, onGetPlayer);
    yield takeEvery(GET_PLAYER_BY_Id, onGetPlayerById);
    yield takeEvery(ADD_PLAYER, onAddPlayer);
    yield takeEvery(EDIT_PLAYER, onEditPlayer);
    yield takeEvery(DELETE_PLAYER, onDeletePlayer);
    /* ========================================================================== */
    yield takeEvery(GET_PLAYER_CATEGORY, onGetPlayerCategory);
    yield takeEvery(GET_PLAYER_CATEGORY, onGetPlayerCategory);
    yield takeEvery(GET_PLAYER_CATEGORY_BY_Id, onGetPlayerCategoryById);
    yield takeEvery(ADD_PLAYER_CATEGORY, onAddPlayerCategory);
    yield takeEvery(EDIT_PLAYER_CATEGORY, onEditPlayerCategory);
    yield takeEvery(DELETE_PLAYER_CATEGORY, onDeletePlayerCategory);
}

export default PlayerSaga;
