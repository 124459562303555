import { LIST_SPORT, LIST_SPORT_BY_Id } from "./actionTypes";

const INIT_STATE = {
    sport: [],
    sportById: [],
    error: {},
};

const Sport = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_SPORT:
            return {
                ...state,
                sport: action.payload,
            };
        case LIST_SPORT_BY_Id:
            return {
                ...state,
                sportById: action.payload,
            };
        default:
            return state;
    }
};

export default Sport;
