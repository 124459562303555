import React, { Component } from "react";

import { Row, Col, Input, Button, Alert, Container, Label } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { checkLogin, apiError } from "../../store/actions";

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import toastr from "toastr";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = { username: "", password: "" };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event, values) {
        if (values.username == 'admin@sportzsquare.in' && values.password == 'admin@0987') {
            localStorage.setItem("authUser", JSON.stringify({ "id": 1, "username": "admin", "password": "123456", "email": "admin@themesdesign.in" }));
            this.props.history.push("/dashboard")
        } else {
            toastr.warning("Invalid Id Password", "")
        }
    }

    componentDidMount() {
        this.props.apiError("");
        document.body.classList.add("auth-body-bg");
    }

    componentWillUnmount() {
        document.body.classList.remove("auth-body-bg");
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <Container fluid className="p-0">
                        <Row className="g-0">
                            <Col lg={4}>
                                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                    <div className="w-100">
                                        <Row className="justify-content-center">
                                            <Col lg={9}>
                                                <div>
                                                    <div className="text-center">
                                                        <div>
                                                            <Link to="/" class="">
                                                                {/* <img
                                                                    src={logodark}
                                                                    alt=""
                                                                    height="20"
                                                                    class="auth-logo logo-dark mx-auto"
                                                                />
                                                                <img
                                                                    src={logolight}
                                                                    alt=""
                                                                    height="20"
                                                                    class="auth-logo logo-light mx-auto"
                                                                /> */}
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "12px" }}> <img
                                                                    src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkU1MzJERjZCN0M4QTExRUE4RjYxRjYyMjZCNjRFRDk2IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkU1MzJERjZDN0M4QTExRUE4RjYxRjYyMjZCNjRFRDk2Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RTUzMkRGNjk3QzhBMTFFQThGNjFGNjIyNkI2NEVEOTYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RTUzMkRGNkE3QzhBMTFFQThGNjFGNjIyNkI2NEVEOTYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4/A+LzAAABeElEQVR42uyX3U3DMBDH7xwP0A3ICHlGArIBHiEbFCZoNyAbNKOkIPHcTgDdoBmgNWdTEDZ5SGwHR8In5Tuyfrn730dQ3lQCEFYgoQAPw5cGf17TunLkEu+0PXLaPRFMDvEtV45h+mQuRlFigZbqQjGFAtoZH3pdFXGBEFrjOnOXQRigswUkoYwJ1AE3Q0ar3sUDQqixbY7fzimr3KemMW/vMKiNOycQ8bLM8s7FlrGAtvjcrK128eBbaF2BDpTaRmhIOws6rHwlyZ1gSCfUTI+Wdja0X/x1pd6SZwp8bXY9oRIBSshgD3VawJZmLjBCTwyBjA8BUandk01fMJuQDZ//0ocalCRVXtWfMmj7QDTMbbWk9+rQEwiOHllUNn0KWMAENkrU2isneJsKZpCHdG86wz2Fx7voOQHp4SrDK4VCT0vf4X80kMPfwaTGYGaWgBJQjPFj0n/+FLKkoZ6ZSaaQJaB/D3SYEc9eAanRdD8DGOWY9YcAAwBpymyPBcWRMwAAAABJRU5ErkJggg=="}
                                                                    alt=""
                                                                    height="25"
                                                                    class="auth-logo"
                                                                />

                                                                    <h2 style={{ margin: 0 }}>
                                                                        Sportzsquare
                                                                    </h2></div>

                                                            </Link>
                                                        </div>

                                                        <h4 className="font-size-18 mt-4">
                                                            Welcome Back !
                                                        </h4>
                                                        <p className="text-muted">
                                                            Sign in to continue to Sports.
                                                        </p>
                                                    </div>

                                                    {this.props.loginError && this.props.loginError ? (
                                                        <Alert color="danger">
                                                            {this.props.loginError}
                                                        </Alert>
                                                    ) : null}

                                                    <div className="p-2 mt-5">
                                                        <AvForm
                                                            className="form-horizontal"
                                                            onValidSubmit={this.handleSubmit}
                                                        >
                                                            <div className="auth-form-group-custom mb-4">
                                                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="username">Email</Label>
                                                                <AvField
                                                                    name="username"
                                                                    value={this.state.username}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="username"
                                                                    validate={{ email: true, required: true }}
                                                                    placeholder="Enter username"
                                                                />
                                                            </div>

                                                            <div className="auth-form-group-custom mb-4">
                                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="userpassword">Password</Label>
                                                                <AvField
                                                                    name="password"
                                                                    value={this.state.password}
                                                                    type="password"
                                                                    className="form-control"
                                                                    id="userpassword"
                                                                    placeholder="Enter password"
                                                                />
                                                            </div>

                                                            {/* <div className="form-check">
                                                                <Input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id="customControlInline"
                                                                />
                                                                <Label
                                                                    className="form-check-label"
                                                                    htmlFor="customControlInline"
                                                                >
                                                                    Remember me
                                                                </Label>
                                                            </div> */}

                                                            <div className="mt-4 text-center">
                                                                <Button
                                                                    color="primary"
                                                                    className="w-md waves-effect waves-light"
                                                                    type="submit"
                                                                >
                                                                    Log In
                                                                </Button>
                                                            </div>

                                                            {/* <div className="mt-4 text-center">
                                                                <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock me-1"></i> Forgot your password?</Link>
                                                            </div> */}
                                                        </AvForm>
                                                    </div>
                                                    {/* 
                                                    <div className="mt-5 text-center">
                                                        <p>
                                                            Don't have an account ?{" "}
                                                            <Link
                                                                to="/register"
                                                                className="fw-medium text-primary"
                                                            >
                                                                {" "}
                                                                Register{" "}
                                                            </Link>{" "}
                                                        </p>
                                                        <p>
                                                            © 2021 Nazox. Crafted with{" "}
                                                            <i className="mdi mdi-heart text-danger"></i> by
                                                            Themesdesign
                                                        </p>
                                                    </div> */}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="authentication-bg">
                                    <div className="bg-overlay"></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = (state) => {
    const { loginError } = state.Login;
    return { loginError };
};

export default withRouter(
    connect(mapStatetoProps, { checkLogin, apiError })(Login)
);
