import { takeEvery, put, call } from "redux-saga/effects";
// Continent Redux States
import { GET_EVENT, ADD_EVENT, EDIT_EVENT, DELETE_EVENTS, GET_EVENT_BY_Id } from "./actionTypes";
import { listEvent, listEventById } from "./actions";
import * as helpers from "../../helpers/backend_Helper";
import toastr from "toastr";

function* onGetEvent() {
    try {
        const response = yield call(helpers.getEvent);
        yield put(listEvent(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onGetEventById({ payload: id }) {
    try {
        const response = yield call(helpers.getEventById, id);
        yield put(listEventById(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onAddEvent({ payload: event }) {
    try {
        const response = yield call(helpers.addEvent, event);
        if (response.status === 1) {
            yield call(onGetEvent);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onEditEvent({ payload: { event, id } }) {
    try {
        const response = yield call(helpers.editEvent, id, event);
        if (response.status === 1) {
            yield call(onGetEvent);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onDeleteEvent({ payload: event }) {
    try {
        const response = yield call(helpers.deleteEvents, event);
        if (response.status === 1) {
            yield call(onGetEvent);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* EventSaga() {
    yield takeEvery(GET_EVENT, onGetEvent);
    yield takeEvery(GET_EVENT_BY_Id, onGetEventById);
    yield takeEvery(ADD_EVENT, onAddEvent);
    yield takeEvery(EDIT_EVENT, onEditEvent);
    yield takeEvery(DELETE_EVENTS, onDeleteEvent);
}

export default EventSaga;
