import { LIST_SESSION, LIST_SESSION_BY_Id } from "./actionTypes";

const INIT_STATE = {
    session: [],
    sessionById: [],
    error: {},
};

const Session = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_SESSION:
            return {
                ...state,
                session: action.payload,
            };
        case LIST_SESSION_BY_Id:
            return {
                ...state,
                sessionById: action.payload,
            };
        case 'clearAllSelect':
            if (state?.sessionById?.length > 0) {
                return {
                    ...state,
                    sessionById: [],
                };
            }
        default:
            return state;
    }
};

export default Session;
