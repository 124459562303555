import { GET_COUNTRY, LIST_COUNTRY, ADD_COUNTRY, EDIT_COUNTRY, DELETE_COUNTRY, GET_COUNTRY_BY_Id, LIST_COUNTRY_BY_Id } from "./actionTypes";

export const getCountry = () => ({
    type: GET_COUNTRY,
});

export const listCountry = (event) => ({
    type: LIST_COUNTRY,
    payload: event,
});

export const getCountryById = (id) => ({
    type: GET_COUNTRY_BY_Id,
    payload: id,
});

export const listCountryById = (event) => ({
    type: LIST_COUNTRY_BY_Id,
    payload: event,
});

export const addCountry = (event) => ({
    type: ADD_COUNTRY,
    payload: event,
});

export const editCountry = (event, id) => ({
    type: EDIT_COUNTRY,
    payload: { event, id },
});

export const deleteCountry = (event) => ({
    type: DELETE_COUNTRY,
    payload: event,
});
