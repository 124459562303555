export * from './layout/actions';

// Authentication module
export * from './auth/register/actions';
export * from './auth/login/actions';
export * from './auth/forgetpwd/actions';

// Calendar actions
export * from "./calendar/actions";

//ecommerce actions
export * from "./e-commerce/actions";

//chat
export * from "./chat/actions"

//Location actions
export * from "./location/Continent/actions"
export * from "./location/Country/actions"
export * from "./location/State/actions"
export * from "./location/City/actions"
export * from "./location/Venue/actions"

export * from "./Sports/actions"

export * from "./Event/actions"

export * from "./Session/actions"

export * from "./Team/actions"

export * from "./Schedule/actions"

export * from "./Article/actions"

export * from "./Category/actions"

export * from "./Group/actions"

export * from "./Player/actions"

export const clearAllSelect = () => ({
    type: 'clearAllSelect',
});