import { takeEvery, put, call } from "redux-saga/effects";
// Continent Redux States
import { GET_COUNTRY, ADD_COUNTRY, EDIT_COUNTRY, DELETE_COUNTRY, GET_COUNTRY_BY_Id } from "./actionTypes";
import { listCountry, listCountryById } from "./actions";
import * as helpers from "../../../helpers/backend_Helper";
import toastr from "toastr";

function* onGetCountry() {
    try {
        const response = yield call(helpers.getCountry);
        yield put(listCountry(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onGetCountryById({ payload: id }) {
    try {
        const response = yield call(helpers.getCountryById, id);
        yield put(listCountryById(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onAddCountry({ payload: event }) {
    try {
        const response = yield call(helpers.addCountry, event);
        if (response.status === 1) {
            yield call(onGetCountry);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onEditCountry({ payload: { event, id } }) {
    try {
        const response = yield call(helpers.editCountry, id, event);
        if (response.status === 1) {
            yield call(onGetCountry);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onDeleteCountry({ payload: event }) {
    try {
        const response = yield call(helpers.deleteCountry, event);
        if (response.status === 1) {
            yield call(onGetCountry);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* CountrySaga() {
    yield takeEvery(GET_COUNTRY, onGetCountry);
    yield takeEvery(GET_COUNTRY_BY_Id, onGetCountryById);
    yield takeEvery(ADD_COUNTRY, onAddCountry);
    yield takeEvery(EDIT_COUNTRY, onEditCountry);
    yield takeEvery(DELETE_COUNTRY, onDeleteCountry);
}

export default CountrySaga;
