import { LIST_SCHEDULE, LIST_SCHEDULE_BY_Id } from "./actionTypes";

const INIT_STATE = {
    schedule: [],
    scheduleById: [],
    error: {},
};

const Schedule = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_SCHEDULE:
            return {
                ...state,
                schedule: action.payload,
            };
        case LIST_SCHEDULE_BY_Id:
            return {
                ...state,
                scheduleById: action.payload,
            };
        default:
            return state;
    }
};

export default Schedule;
