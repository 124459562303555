import { GET_PLAYER, LIST_PLAYER, ADD_PLAYER, EDIT_PLAYER, DELETE_PLAYER, GET_PLAYER_BY_Id, LIST_PLAYER_BY_Id, GET_PLAYER_CATEGORY, LIST_PLAYER_CATEGORY, LIST_PLAYER_CATEGORY_BY_Id, ADD_PLAYER_CATEGORY, EDIT_PLAYER_CATEGORY, DELETE_PLAYER_CATEGORY, GET_PLAYER_CATEGORY_BY_Id } from "./actionTypes";

export const getPlayer = () => ({
    type: GET_PLAYER,
});

export const listPlayer = (event) => ({
    type: LIST_PLAYER,
    payload: event,
});

export const getPlayerById = (id) => ({
    type: GET_PLAYER_BY_Id,
    payload: id,
});

export const listPlayerById = (event) => ({
    type: LIST_PLAYER_BY_Id,
    payload: event,
});

export const addPlayer = (event) => ({
    type: ADD_PLAYER,
    payload: event,
});

export const editPlayer = (event, id) => ({
    type: EDIT_PLAYER,
    payload: { event, id },
});

export const deletePlayer = (event) => ({
    type: DELETE_PLAYER,
    payload: event,
});

/* ========================================================================== */

export const getPlayerCategory = () => ({
    type: GET_PLAYER_CATEGORY,
});

export const listPlayerCategory = (event) => ({
    type: LIST_PLAYER_CATEGORY,
    payload: event,
});

export const getPlayerCategoryById = (id) => ({
    type: GET_PLAYER_CATEGORY_BY_Id,
    payload: id,
});

export const listPlayerCategoryById = (event) => ({
    type: LIST_PLAYER_CATEGORY_BY_Id,
    payload: event,
});

export const addPlayerCategory = (event) => ({
    type: ADD_PLAYER_CATEGORY,
    payload: event,
});

export const editPlayerCategory = (event, id) => ({
    type: EDIT_PLAYER_CATEGORY,
    payload: { event, id },
});

export const deletePlayerCategory = (event) => ({
    type: DELETE_PLAYER_CATEGORY,
    payload: event,
});