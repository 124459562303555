import { LIST_COUNTRY, LIST_COUNTRY_BY_Id } from "./actionTypes";

const INIT_STATE = {
    country: [],
    countryById: [],
    error: {},
};

const Country = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_COUNTRY:
            return {
                ...state,
                country: action.payload,
            };
        case LIST_COUNTRY_BY_Id:
            return {
                ...state,
                countryById: action.payload,
            };
        case 'clearAllSelect':
            if (state?.countryById?.length > 0) {
                return {
                    ...state,
                    countryById: [],
                };
            }
        default:
            return state;
    }
};

export default Country;
