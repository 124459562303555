import { GET_STATE, LIST_STATE, ADD_STATE, EDIT_STATE, DELETE_STATE, GET_STATE_BY_Id, LIST_STATE_BY_Id } from "./actionTypes";

export const getState = () => ({
    type: GET_STATE,
});

export const listState = (event) => ({
    type: LIST_STATE,
    payload: event,
});

export const getStateById = (id) => ({
    type: GET_STATE_BY_Id,
    payload: id,
});

export const listStateById = (event) => ({
    type: LIST_STATE_BY_Id,
    payload: event,
});

export const addState = (event) => ({
    type: ADD_STATE,
    payload: event,
});

export const editState = (event, id) => ({
    type: EDIT_STATE,
    payload: { event, id },
});

export const deleteState = (event) => ({
    type: DELETE_STATE,
    payload: event,
});
