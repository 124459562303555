import { ADD_CONTINENT_FAIL, ADD_CONTINENT_SUCCESS, DELETE_CONTINENT_FAIL, DELETE_CONTINENT_SUCCESS, GET_CONTINENT_FAIL, GET_CONTINENT_SUCCESS } from "./actionTypes";

const INIT_STATE = {
    continent: [],
    error: {},
};

const Continent = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CONTINENT_SUCCESS:
            return {
                ...state,
                continent: action.payload,
            };
        case GET_CONTINENT_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_CONTINENT_SUCCESS:
            return {
                ...state,
                continent: [...state.continent, action.payload],
            };

        case ADD_CONTINENT_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_CONTINENT_SUCCESS:
            return {
                ...state,
                continent: state.continent.filter((cn) => cn._id.toString() !== action.payload._id.toString()),
            };

        case DELETE_CONTINENT_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default Continent;
