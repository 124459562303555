import React, { useEffect, useState } from "react";
import {
      Table,
      Row,
      Col,
      Card,
      CardBody,
      Container,
      Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import * as Actions from "../../store/actions";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "./deleteModal";
import SportsModal from "../../components/Modal/SportsModal";

function Sports(props) {
      const [breadcrumbItems] = useState([{ title: "Sports", link: "#" }])
      const [isModal, setIsModal] = useState(false);
      const [isDeleteModal, setIsDeleteModal] = useState(false);
      const [selectedRow, setSelectedRow] = useState(null)

      useEffect(() => {
            props.getSport();
      }, []);

      const handleDeleteEvent = () => {
            props.deleteSport(selectedRow?._id || "");
            setIsDeleteModal(false);
            setSelectedRow(null)
      }

      return (
            <React.Fragment>
                  <div className="page-content">
                        <Container fluid>
                              <Breadcrumbs
                                    title="Sports"
                                    breadcrumbItems={breadcrumbItems}
                              />
                              <Row>
                                    <Col lg={12}>
                                          <Card>
                                                <CardBody>
                                                      <div className="d-flex  justify-content-between mb-2">
                                                            <h4 className="card-title">Sports List</h4>
                                                            <Button
                                                                  onClick={() => setIsModal(true)}
                                                                  color="success"
                                                                  className="waves-effect waves-light me-1 float-end"
                                                            >
                                                                  Add New Sports
                                                            </Button>
                                                      </div>

                                                      <div className="table-responsive">
                                                            <Table className="table table-bordered table-hover">
                                                                  <thead className="table-light table-nowrap">
                                                                        <tr>
                                                                              <th>#</th>
                                                                              <th>Sport</th>
                                                                              <th style={{ width: "100px" }}>Action</th>
                                                                        </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                        {props.sport.map((x, i) => {
                                                                              return (
                                                                                    <tr key={String(i)}>
                                                                                          <th scope="row">{i + 1}</th>
                                                                                          <td>{x?.name}</td>
                                                                                          <td>
                                                                                                <Link to="#" className="me-3 text-primary" onClick={() => { setSelectedRow(x); setIsModal(true); }}>
                                                                                                      <i className="mdi mdi-pencil font-size-18"></i>
                                                                                                </Link>
                                                                                                <Link to="#" className="text-danger" onClick={() => { setIsDeleteModal(true); setSelectedRow(x) }}>
                                                                                                      <i className="mdi mdi-trash-can font-size-18"></i>
                                                                                                </Link>
                                                                                          </td>
                                                                                    </tr>
                                                                              )
                                                                        })}
                                                                  </tbody>
                                                            </Table>
                                                      </div>
                                                </CardBody>
                                          </Card>
                                    </Col>
                              </Row>
                        </Container>
                  </div>

                  {isModal &&
                        <SportsModal
                              showModal={isModal}
                              closeModal={() => { setSelectedRow(null); setIsModal(false) }}
                              item={selectedRow}
                              {...props}
                        />
                  }

                  <DeleteModal show={isDeleteModal} onDeleteClick={handleDeleteEvent} onCloseClick={() => setIsDeleteModal(false)} />
            </React.Fragment>
      )
}

function mapDispatchToProps(dispatch) {
      return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = ({ Sport }) => {
      return {
            sport: Sport.sport,
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sports);