import { takeEvery, put, call } from "redux-saga/effects";
// Continent Redux States
import { GET_SCHEDULE, LIST_SCHEDULE, ADD_SCHEDULE, EDIT_SCHEDULE, DELETE_SCHEDULE, GET_SCHEDULE_BY_Id } from "./actionTypes";
import { listSchedule, listScheduleById } from "./actions";
import * as helpers from "../../helpers/backend_Helper";
import toastr from "toastr";

function* onGetSchedule() {
    try {
        const response = yield call(helpers.getSchedule);
        yield put(listSchedule(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onGetScheduleById({ payload: id }) {
    try {
        const response = yield call(helpers.getScheduleById, id);
        yield put(listScheduleById(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onAddSchedule({ payload: event }) {
    try {
        const response = yield call(helpers.addSchedule, event);
        if (response.status === 1) {
            yield call(onGetSchedule);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onEditSchedule({ payload: { event, id } }) {
    try {
        const response = yield call(helpers.editSchedule, id, event);
        if (response.status === 1) {
            yield call(onGetSchedule);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onDeleteSchedule({ payload: event }) {
    try {
        const response = yield call(helpers.deleteSchedule, event);
        if (response.status === 1) {
            yield call(onGetSchedule);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* ScheduleSaga() {
    yield takeEvery(GET_SCHEDULE, onGetSchedule);
    yield takeEvery(GET_SCHEDULE_BY_Id, onGetScheduleById);
    yield takeEvery(ADD_SCHEDULE, onAddSchedule);
    yield takeEvery(EDIT_SCHEDULE, onEditSchedule);
    yield takeEvery(DELETE_SCHEDULE, onDeleteSchedule);
}

export default ScheduleSaga;
