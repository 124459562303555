import React, { Component, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import toastr from "toastr";
import { Table, Row, Col, Card, CardBody, Container, Button, Label, Modal, ModalHeader, ModalBody, Form, Input, ModalFooter } from "reactstrap";

export default function UpdateContinent(props) {
    const [name, setName] = useState(null);
    const [id, setId] = useState(null);

    useEffect(() => {
        if (props.item) {
            console.log(props.item);
            setName(props?.item?.name);
            setId(props?.item?._id);
        }
    }, [props.showModal]);

    const handleChange = (event) => {
        setName(event.target.value);
    };

    const handleSubmit = async (e) => {
        if (!name) {
            toastr.warning("Please Enter Continent", "");
            return;
        }
        props.editContinent({ name: name }, id);
        props.closeModal();
    };

    return (
        <Modal
            isOpen={props.showModal}
            toggle={() => props.closeModal()}
        >
            <ModalHeader toggle={() => props.closeModal()}>Update Continent</ModalHeader>
            <ModalBody>
                <Form>
                    <Row>
                        <Col lg={12}>
                            <div className="mb-3">
                                <Label className="form-label" htmlFor="name">Continent</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="Continent"
                                    placeholder="Enter Continent"
                                    value={name}
                                    onChange={handleChange}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <ModalFooter className="pb-0">
                                <Button color="light" onClick={() => props.closeModal()}>Close</Button>
                                <Button onClick={() => handleSubmit()} color="primary">Submit</Button>
                            </ModalFooter>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    )
} 