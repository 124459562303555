import { takeEvery, put, call } from "redux-saga/effects";
// Continent Redux States
import { GET_ARTICLE, ADD_ARTICLE, EDIT_ARTICLE, DELETE_ARTICLE, GET_ARTICLE_BY_Id, GET_PLAYER } from "./actionTypes";
import { listArticle, listArticleById, listPlayer } from "./actions";
import * as helpers from "../../helpers/backend_Helper";
import toastr from "toastr";

function* onGetArticle() {
    try {
        const response = yield call(helpers.getArticle);
        yield put(listArticle(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onGetArticleById({ payload: id }) {
    try {
        const response = yield call(helpers.getArticleById, id);
        yield put(listArticleById(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onAddArticle({ payload: event }) {
    try {
        const response = yield call(helpers.addArticle, event);
        if (response.status === 1) {
            yield call(onGetArticle);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onEditArticle({ payload: { event, id } }) {
    try {
        const response = yield call(helpers.editArticle, id, event);
        if (response.status === 1) {
            yield call(onGetArticle);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onDeleteArticle({ payload: event }) {
    try {
        const response = yield call(helpers.deleteArticle, event);
        if (response.status === 1) {
            yield call(onGetArticle);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* ArticleSaga() {
    yield takeEvery(GET_ARTICLE, onGetArticle);
    yield takeEvery(GET_ARTICLE_BY_Id, onGetArticleById);
    yield takeEvery(ADD_ARTICLE, onAddArticle);
    yield takeEvery(EDIT_ARTICLE, onEditArticle);
    yield takeEvery(DELETE_ARTICLE, onDeleteArticle);
}

export default ArticleSaga;
