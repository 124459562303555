import { GET_EVENT, LIST_EVENT, ADD_EVENT, EDIT_EVENT, DELETE_EVENTS, GET_EVENT_BY_Id, LIST_EVENT_BY_Id } from "./actionTypes";

export const getEvent = () => ({
    type: GET_EVENT,
});

export const listEvent = (event) => ({
    type: LIST_EVENT,
    payload: event,
});

export const getEventById = (id) => ({
    type: GET_EVENT_BY_Id,
    payload: id,
});

export const listEventById = (event) => ({
    type: LIST_EVENT_BY_Id,
    payload: event,
});

export const addEvent = (event) => ({
    type: ADD_EVENT,
    payload: event,
});

export const editEvent = (event, id) => ({
    type: EDIT_EVENT,
    payload: { event, id },
});

export const deleteEvents = (event) => ({
    type: DELETE_EVENTS,
    payload: event,
});
