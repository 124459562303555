import { takeEvery, put, call } from "redux-saga/effects";
// Continent Redux States
import { ADD_NEW_CONTINENT, DELETE_CONTINENT, GET_CONTINENT, EDIT_CONTINENT } from "./actionTypes";
import { getContinentSuccess, getContinentFail, addContinentSuccess, addContinentFail, deleteContinentSuccess, deleteContinentFail } from "./actions";
import { addContinents, deleteContinents, getContinents, editContinents } from "../../../helpers/backend_Helper";
import toastr from "toastr";

function* onGetContinent() {
    try {
        const response = yield call(getContinents);
        yield put(getContinentSuccess(response.data));
    } catch (error) {
        yield put(getContinentFail(error));
    }
}

function* onAddNewContinents({ payload: event }) {
    try {
        const response = yield call(addContinents, event);
        if (response.status === 1) {
            yield call(onGetContinent);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        yield put(addContinentFail(error));
    }
}

function* onEditContinents({ payload: { event, id } }) {
    try {
        const response = yield call(editContinents, id, event);
        if (response.status === 1) {
            yield call(onGetContinent);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onDeleteContinents({ payload: event }) {
    try {
        const response = yield call(deleteContinents, event);
        if (response.status === 1) {
            yield call(onGetContinent);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        yield put(deleteContinentFail(error));
    }
}

function* ContinentSaga() {
    yield takeEvery(GET_CONTINENT, onGetContinent);
    yield takeEvery(ADD_NEW_CONTINENT, onAddNewContinents);
    yield takeEvery(DELETE_CONTINENT, onDeleteContinents);
    yield takeEvery(EDIT_CONTINENT, onEditContinents);
}

export default ContinentSaga;
