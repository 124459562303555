import React, { useEffect, useState } from 'react';
import {
      Row,
      Col,
      Button,
      Label,
      Modal,
      ModalHeader,
      ModalBody,
      Form,
      Input,
      ModalFooter,
} from "reactstrap";
import Select from "react-select";
import toastr from "toastr";

function StateModal(props) {
      const { isAddModal, closeModal, continent, country } = props;
      const [selectedContinent, setSelectedContinent] = useState(null);
      const [selectedCountry, setSelectedCountry] = useState(null);
      const [stateName, setStateName] = useState('');
      const [id, setId] = useState('');

      useEffect(() => {
            if (props.item) {
                  console.log(props.item, '---')
                  setSelectedContinent(props?.item?.continent);
                  props.getCountryById(props?.item?.continent?._id);
                  setSelectedCountry(props?.item?.country);
                  setStateName(props?.item?.name);
                  setId(props?.item?._id);
            }
      }, [props.isAddModal]);

      const handleSelectGroup = (name, value) => {
            if (name == 'continent') {
                  if (JSON.stringify(value) !== JSON.stringify(selectedContinent)) {
                        props.getCountryById(value._id);
                        setSelectedCountry(null);
                  }
                  setSelectedContinent(value);
            } else {
                  setSelectedCountry(value);
            }
      }

      const handleChange = (event) => {
            setStateName(event.target.value)
      };

      const handleSubmit = async (e) => {
            if (!selectedContinent && toastr.warning("Please Select Continent", "")) return;
            if (!selectedCountry && toastr.warning("Please Select Country", "")) return;
            if (!stateName && toastr.warning("Please Enter State", "")) return;

            if (props.item) {
                  props.editState({ name: stateName, continent: selectedContinent._id, country: selectedCountry._id }, id);
            } else {
                  props.addState({ name: stateName, continent: selectedContinent._id, country: selectedCountry._id });
            }
            props.closeModal();
      };

      const _closeModal = () => {
            closeModal();
            props.clearAllSelect()
      }

      return (
            <Modal
                  size="lg"
                  isOpen={isAddModal}
                  toggle={() => _closeModal()}
            >
                  <ModalHeader
                        toggle={() => _closeModal()}
                  ></ModalHeader>
                  <ModalBody>
                        <Form>
                              <Row>
                                    <Col lg={6}>
                                          <div className="mb-3">
                                                <Label className="form-label" htmlFor="email">
                                                      Continent
                                                </Label>
                                                <Select
                                                      value={selectedContinent}
                                                      onChange={(value) => handleSelectGroup('continent', value)}
                                                      options={continent}
                                                      classNamePrefix="select2-selection"
                                                      placeholder="Select Continent"
                                                      getOptionLabel={(option) => option.name}
                                                      getOptionValue={(option) => option?._id || option}
                                                />
                                          </div>
                                    </Col>
                                    <Col lg={6}>
                                          <div className="mb-3">
                                                <Label className="form-label" htmlFor="email">
                                                      Country
                                                </Label>
                                                <Select
                                                      value={selectedCountry}
                                                      onChange={(value) => handleSelectGroup('country', value)}
                                                      options={country}
                                                      classNamePrefix="select2-selection"
                                                      placeholder="Select Country"
                                                      getOptionLabel={(option) => option.name}
                                                      getOptionValue={(option) => option?._id || option}
                                                />
                                          </div>
                                    </Col>
                              </Row>
                              <Row>
                                    <Col lg={6}>
                                          <div className="mb-3">
                                                <Label className="form-label" htmlFor="email">
                                                      State
                                                </Label>
                                                <Input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Enter State"
                                                      required
                                                      value={stateName}
                                                      onChange={handleChange}
                                                />
                                          </div>
                                    </Col>
                              </Row>
                              <Row>
                                    <Col lg={12}>
                                          <ModalFooter className="pb-0">
                                                <Button color="light" onClick={() => _closeModal()} >Close</Button>
                                                <Button color="primary" onClick={() => handleSubmit()}>Submit</Button>
                                          </ModalFooter>
                                    </Col>
                              </Row>
                        </Form>
                  </ModalBody>
            </Modal>
      )
}

export default StateModal;