import React, { useEffect, useState } from "react";
import { Table, Row, Col, Card, CardBody, Container, Button, } from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import DeleteModal from "./deleteModal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../store/actions";
import VenueModal from "../../components/Modal/VenueModal";

function Venue(props) {
      const [breadcrumbItems] = useState([
            { title: "Location", link: "#" },
            { title: "Venue", link: "#" },
      ])
      const [isAddModal, setIsAddModal] = useState(false);
      const [isDeleteModal, setIsDeleteModal] = useState(false);
      const [selectedRow, setSelectedRow] = useState(null);

      useEffect(() => {
            props.getVenue();
            // props.getContinent();
      }, []);

      const handleDeleteEvent = () => {
            props.deleteVenue(selectedRow?._id || "");
            setIsDeleteModal(false);
            setSelectedRow(null)
      }

      return (
            <React.Fragment>
                  <div className="page-content">
                        <Container fluid>
                              <Breadcrumbs title="Venue" breadcrumbItems={breadcrumbItems} />
                              <Row>
                                    <Col lg={12}>
                                          <Card>
                                                <CardBody>
                                                      <div className="d-flex  justify-content-between mb-2">
                                                            <h4 className="card-title">Venue List</h4>
                                                            <Button onClick={() => props.history.push('/venueAction')} color="success" className="waves-effect waves-light me-1 float-end">
                                                                  Add New Venue
                                                            </Button>
                                                      </div>

                                                      <div className="table-responsive venue-table">
                                                            <Table className="table table-bordered table-hover" >
                                                                  <thead className="table-light table-nowrap">
                                                                        <tr>
                                                                              <th>#</th>
                                                                              <th>Image</th>
                                                                              <th>Stadium Name</th>
                                                                              <th className="address">Address</th>
                                                                              <th>Pin-Code</th>
                                                                              <th>Continent</th>
                                                                              <th>Country</th>
                                                                              <th>State</th>
                                                                              <th>City</th>
                                                                              <th>Category</th>
                                                                              <th>Action</th>
                                                                        </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                        {props.venue.map((x, i) => {
                                                                              return (
                                                                                    <tr key={String(i)}>
                                                                                          <th scope="row">{i + 1}</th>
                                                                                          <td><img src={x?.image} /></td>
                                                                                          <td>{x?.stadium_name}</td>
                                                                                          <td>{x?.stadium_address}</td>
                                                                                          <td>{x?.stadium_pincode}</td>
                                                                                          <td>{x?.continent?.name}</td>
                                                                                          <td>{x?.country?.name}</td>
                                                                                          <td>{x?.state?.name}</td>
                                                                                          <td>{x?.city?.name}</td>
                                                                                          <td>{x?.sport?.name}</td>
                                                                                          <td>
                                                                                                <Link to={{ pathname: '/venueAction', state: { editItem: x } }} className="me-3 text-primary">
                                                                                                      <i className="mdi mdi-pencil font-size-18"></i>
                                                                                                </Link>
                                                                                                <Link to="#" className="text-danger" onClick={() => { setIsDeleteModal(true); setSelectedRow(x) }}>
                                                                                                      <i className="mdi mdi-trash-can font-size-18"></i>
                                                                                                </Link>
                                                                                          </td>
                                                                                    </tr>
                                                                              )
                                                                        })}
                                                                  </tbody>
                                                            </Table>
                                                      </div>
                                                </CardBody>
                                          </Card>
                                    </Col>
                              </Row>
                        </Container>
                  </div>

                  {isAddModal &&
                        <VenueModal
                              isAddModal={isAddModal}
                              closeModal={() => { setSelectedRow(null); setIsAddModal(false) }}
                              item={selectedRow}
                              {...props}
                        />
                  }

                  <DeleteModal show={isDeleteModal} onDeleteClick={handleDeleteEvent} onCloseClick={() => setIsDeleteModal(false)} />
            </React.Fragment>
      )
}

function mapDispatchToProps(dispatch) {
      return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = ({ Continent, Country, State, City, Venue }) => {
      return {
            continent: Continent.continent,
            country: Country.countryById,
            state: State.stateById,
            city: City.cityById,
            venue: Venue.venue,
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(Venue);