import { GET_TEAM, LIST_TEAM, ADD_TEAM, EDIT_TEAM, DELETE_TEAM, GET_TEAM_BY_Id, LIST_TEAM_BY_Id } from "./actionTypes";

export const getTeam = () => ({
    type: GET_TEAM,
});

export const listTeam = (event) => ({
    type: LIST_TEAM,
    payload: event,
});

export const getTeamById = (id) => ({
    type: GET_TEAM_BY_Id,
    payload: id,
});

export const listTeamById = (event) => ({
    type: LIST_TEAM_BY_Id,
    payload: event,
});

export const addTeam = (event) => ({
    type: ADD_TEAM,
    payload: event,
});

export const editTeam = (event, id) => ({
    type: EDIT_TEAM,
    payload: { event, id },
});

export const deleteTeam = (event) => ({
    type: DELETE_TEAM,
    payload: event,
});
