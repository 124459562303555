import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = accessToken

//apply base url for axios
export const BASE_URL = "https://api.sportzsquare.net/";
// export const BASE_URL = "http://192.168.31.131:3000/";
// export const BASE_URL = "http://192.168.31.69:5000/"; // Vijay ip
// export const BASE_URL = "http://192.168.31.48:3000/"; // Raj ip
export const API_URL = `${BASE_URL}api/`;
export const API_URL_IMAGE = `${BASE_URL}uploads/`

const axiosApi = axios.create({
    baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
    response => response,
    error => Promise.reject(error)
)

export async function get(url, config = {}) {
    return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
    return axiosApi
        .post(url, data, { ...config })
        .then(response => response.data)
}

export async function put(url, data, config = {}) {
    return axiosApi
        .put(url, data, { ...config })
        .then(response => response.data)
}

export async function del(url, config = {}) {
    return await axiosApi
        .delete(url, { ...config })
        .then(response => response.data)
}
