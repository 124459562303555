/* CONTINENT  */

// Get
export const GET_CONTINENT = "GET_CONTINENT";
export const GET_CONTINENT_SUCCESS = "GET_CONTINENT_SUCCESS";
export const GET_CONTINENT_FAIL = "GET_CONTINENT_FAIL";

// ADD

export const ADD_NEW_CONTINENT = "ADD_NEW_CONTINENT";
export const ADD_CONTINENT_SUCCESS = "ADD_CONTINENT_SUCCESS";
export const ADD_CONTINENT_FAIL = "ADD_CONTINENT_FAIL";

// Delete

export const DELETE_CONTINENT = "DELETE_CONTINENT";
export const DELETE_CONTINENT_SUCCESS = "DELETE_CONTINENT_SUCCESS";
export const DELETE_CONTINENT_FAIL = "DELETE_CONTINENT_FAIL";


export const EDIT_CONTINENT = "EDIT_CONTINENT";
