import React, { useEffect, useState } from 'react';
import {
      Row,
      Col,
      Button,
      Label,
      Modal,
      ModalHeader,
      ModalBody,
      Form,
      Input,
      ModalFooter,
} from "reactstrap";
import Select from "react-select";
import toastr from "toastr";

function SessionModal(props) {
      const { showModal, closeModal, event } = props;
      const [inputValue, setInputValue] = useState({
            name: '',
            year: ''
      });
      const [selectedEvent, setSelectedEvent] = useState(null);
      const [id, setId] = useState(null);

      useEffect(() => {
            if (props.item) {
                  console.log(props.item, '-----');
                  setInputValue({
                        name: props?.item?.name,
                        year: props?.item?.year
                  });
                  setSelectedEvent(props?.item?.event);
                  setId(props?.item?._id);
            }
      }, [props.showModal]);

      const handleChange = (name, value) => {
            setInputValue(pre => ({ ...pre, [name]: value }))
      };

      const handleSelectGroup = (value) => {
            setSelectedEvent(value);
      }

      const handleSubmit = async (e) => {
            if (!inputValue.name && toastr.warning("Please Enter Session", "")) return;
            if (!inputValue.year && toastr.warning("Please Enter Year", "")) return;
            if (!selectedEvent && toastr.warning("Please Select Event", "")) return;

            if (props.item) {
                  props.editSession({ name: inputValue.name, year: inputValue.year, event: selectedEvent._id }, id);
            } else {
                  props.addSession({ name: inputValue.name, year: inputValue.year, event: selectedEvent._id });
            }
            props.closeModal();
      };

      return (
            <Modal
                  size="lg"
                  isOpen={showModal}
                  toggle={() => closeModal()}
            >
                  <ModalHeader toggle={() => closeModal()} ></ModalHeader>
                  <ModalBody>
                        <Form>
                              <Row>
                                    <Col lg={6}>
                                          <div className="mb-3">
                                                <Label className="form-label" htmlFor="email">
                                                      Session Name
                                                </Label>
                                                <Input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Enter Session"
                                                      required
                                                      value={inputValue['name']}
                                                      onChange={e => handleChange('name', e.target.value)}
                                                />
                                          </div>
                                    </Col>
                                    <Col lg={6}>
                                          <div className="mb-3">
                                                <Label className="form-label" htmlFor="email">
                                                      Year
                                                </Label>
                                                <Input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Enter Year"
                                                      required
                                                      value={inputValue['year']}
                                                      onChange={e => handleChange('year', e.target.value.replace(/\D/g, ""))}
                                                />
                                          </div>
                                    </Col>
                              </Row>
                              <Row>
                                    <Col lg={6}>
                                          <div className="mb-3">
                                                <Label className="form-label" htmlFor="email">
                                                      Event List
                                                </Label>
                                                <Select
                                                      value={selectedEvent}
                                                      onChange={handleSelectGroup}
                                                      options={event}
                                                      classNamePrefix="select2-selection"
                                                      placeholder="Select Event"
                                                      getOptionLabel={(option) => option.name}
                                                      getOptionValue={(option) => option?._id || option}
                                                />
                                          </div>
                                    </Col>
                              </Row>
                              <Row>
                                    <Col lg={12}>
                                          <ModalFooter className="pb-0">
                                                <Button color="light" onClick={() => closeModal()}>Close</Button>
                                                <Button color="primary" onClick={handleSubmit}>Submit</Button>
                                          </ModalFooter>
                                    </Col>
                              </Row>
                        </Form>
                  </ModalBody>
            </Modal>
      )
}

export default SessionModal;