import React, { useEffect, useState } from 'react';
import toastr from "toastr";
import { Row, Col, Button, Label, Modal, ModalHeader, ModalBody, Form, Input, ModalFooter } from "reactstrap";
import Select from "react-select";

export default function CountryModal(props) {
    const [selectedContinent, setSelectedContinent] = useState(null);
    const [countryName, setCountryName] = useState('');
    const [id, setId] = useState(null);

    useEffect(() => {
        if (props.item) {
            setSelectedContinent(props?.item?.continent);
            setCountryName(props?.item?.name);
            setId(props?.item?._id);
        }
    }, [props.showModal]);

    const handleChange = (event) => {
        setCountryName(event.target.value)
    };

    const handleSelectGroup = (e) => {
        setSelectedContinent(e)
    }

    const handleSubmit = async (e) => {
        if (!selectedContinent) {
            toastr.warning("Please Select Continent", "");
            return;
        }
        if (!countryName) {
            toastr.warning("Please Enter Continent", "");
            return;
        }
        if (props.item) {
            props.editCountry({ name: countryName, continent: selectedContinent._id }, id);
        } else {
            props.addCountry({ name: countryName, continent: selectedContinent._id });
        }
        props.closeModal();
    };

    return (
        <Modal
            size="lg"
            isOpen={props.showModal}
            toggle={() => props.closeModal()}
        >
            <ModalHeader toggle={() => props.closeModal()}>{props.item ? 'Update Continent' : 'Add Continent'}</ModalHeader>
            <ModalBody>
                <Form>
                    <Row>
                        <Col lg={6}>
                            <div className="mb-3">
                                <Label className="form-label">Continent</Label>
                                <Select
                                    value={selectedContinent}
                                    onChange={handleSelectGroup}
                                    options={props?.continent || []}
                                    classNamePrefix="select2-selection"
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option?._id || option}
                                />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="mb-3">
                                <Label className="form-label">Country</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Country"
                                    value={countryName}
                                    onChange={handleChange}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <ModalFooter className="pb-0">
                                <Button color="light" onClick={() => props.closeModal()}>Close</Button>
                                <Button onClick={() => handleSubmit()} color="primary">Submit</Button>
                            </ModalFooter>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    )
} 