import React, { Component, useEffect, useState } from "react";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import IndiaLogo from "../../assets/images/flags/india.png";
import * as Actions from "../../store/actions";
import DeleteModal from "../Tables/deleteModal";
import { API_URL_IMAGE } from "../../helpers/api_helper";
// const API_URL = "http://192.168.31.131:3000/uploads/"

function Team(props) {
    const { team } = props;
    const [breadcrumbItems] = useState([{ title: "Team", link: "#" }])
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        props.getTeam();
    }, []);

    const handleDeleteEvent = () => {
        props.deleteTeam(selectedRow?._id || "");
        setIsDeleteModal(false);
        setSelectedRow(null)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Team" breadcrumbItems={breadcrumbItems} />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex  justify-content-between mb-2">
                                        <h4 className="card-title">Team List</h4>
                                        <Button onClick={() => props.history.push('/teamAction')} color="success" className="waves-effect waves-light me-1 float-end">
                                            Add New Team
                                        </Button>
                                    </div>
                                    <div className="table-responsive team-table">
                                        <Table className="table table-bordered table-hover">
                                            <thead className="table-light table-nowrap">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Team Logo</th>
                                                    <th>Team Name</th>
                                                    <th>Sport</th>
                                                    <th>Category</th>
                                                    <th>Type</th>
                                                    <th>Continent</th>
                                                    <th>Country</th>
                                                    <th>Event</th>
                                                    <th>Sessions</th>
                                                    <th style={{ width: "100px" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    team.map((e, i) => {
                                                        return (
                                                            <tr key={String(i)}>
                                                                <th scope="row">{i + 1}</th>
                                                                {/* <td><img src={API_URL_IMAGE + e?.logo} /></td> */}
                                                                <td><img src={e?.logo} /></td>
                                                                <td>{e?.name}</td>
                                                                <td>{e?.sport?.name}</td>
                                                                <td>{e?.category}</td>
                                                                <td>{e?.type}</td>
                                                                <td>{e?.continent?.name}</td>
                                                                <td>{e?.country?.name}</td>
                                                                <td>{e?.event?.name}</td>
                                                                <td>{e?.season?.name}</td>
                                                                <td>
                                                                    {/* <Link to="/city" className="me-3 text-success">
                                                                        <i className="mdi mdi-eye font-size-18"></i>
                                                                    </Link> */}
                                                                    <Link to={{ pathname: '/teamAction', state: { editItem: e } }} className="me-3 text-primary">
                                                                        <i className="mdi mdi-pencil font-size-18"></i>
                                                                    </Link>
                                                                    <Link to="#" className="text-danger" onClick={() => { setIsDeleteModal(true); setSelectedRow(e) }}>
                                                                        <i className="mdi mdi-trash-can font-size-18"></i>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <DeleteModal show={isDeleteModal} onDeleteClick={handleDeleteEvent} onCloseClick={() => setIsDeleteModal(false)} />
        </React.Fragment>
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = ({ Team }) => {
    return {
        team: Team.team,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Team);