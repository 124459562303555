import { GET_VENUE, LIST_VENUE, ADD_VENUE, EDIT_VENUE, DELETE_VENUE, GET_VENUE_BY_Id, LIST_VENUE_BY_Id } from "./actionTypes";

export const getVenue = () => ({
    type: GET_VENUE,
});

export const listVenue = (event) => ({
    type: LIST_VENUE,
    payload: event,
});

export const getVenueById = (id) => ({
    type: GET_VENUE_BY_Id,
    payload: id,
});

export const listVenueById = (event) => ({
    type: LIST_VENUE_BY_Id,
    payload: event,
});

export const addVenue = (event) => ({
    type: ADD_VENUE,
    payload: event,
});

export const editVenue = (event, id) => ({
    type: EDIT_VENUE,
    payload: { event, id },
});

export const deleteVenue = (event) => ({
    type: DELETE_VENUE,
    payload: event,
});
