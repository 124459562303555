export const GET_PLAYER = "GET_PLAYER";
export const LIST_PLAYER = "LIST_PLAYER";
export const GET_PLAYER_BY_Id = "GET_PLAYER_BY_Id";
export const LIST_PLAYER_BY_Id = "LIST_PLAYER_BY_Id";
export const ADD_PLAYER = "ADD_PLAYER";
export const EDIT_PLAYER = "EDIT_PLAYER";
export const DELETE_PLAYER = "DELETE_PLAYER";

export const GET_PLAYER_CATEGORY = "GET_PLAYER_CATEGORY";
export const LIST_PLAYER_CATEGORY = "LIST_PLAYER_CATEGORY";
export const GET_PLAYER_CATEGORY_BY_Id = "GET_PLAYER_CATEGORY_BY_Id";
export const LIST_PLAYER_CATEGORY_BY_Id = "LIST_PLAYER_CATEGORY_BY_Id";
export const ADD_PLAYER_CATEGORY = "ADD_PLAYER_CATEGORY";
export const EDIT_PLAYER_CATEGORY = "EDIT_PLAYER_CATEGORY";
export const DELETE_PLAYER_CATEGORY = "DELETE_PLAYER_CATEGORY"; 