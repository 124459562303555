import { takeEvery, put, call } from "redux-saga/effects";
// Continent Redux States
import { GET_CATEGORY, ADD_CATEGORY, EDIT_CATEGORY, DELETE_CATEGORY, GET_CATEGORY_BY_Id } from "./actionTypes";
import { listCategory, listCategoryById } from "./actions";
import * as helpers from "../../helpers/backend_Helper";
import toastr from "toastr";

function* onGetCategory() {
    try {
        const response = yield call(helpers.getCategory);
        yield put(listCategory(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onGetCategoryById({ payload: id }) {
    try {
        const response = yield call(helpers.getCategoryById, id);
        yield put(listCategoryById(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onAddCategory({ payload: event }) {
    try {
        const response = yield call(helpers.addCategory, event);
        if (response.status === 1) {
            yield call(onGetCategory);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onEditCategory({ payload: { event, id } }) {
    try {
        const response = yield call(helpers.editCategory, id, event);
        if (response.status === 1) {
            yield call(onGetCategory);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onDeleteCategory({ payload: event }) {
    try {
        const response = yield call(helpers.deleteCategory, event);
        if (response.status === 1) {
            yield call(onGetCategory);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* CategorySaga() {
    yield takeEvery(GET_CATEGORY, onGetCategory);
    yield takeEvery(GET_CATEGORY_BY_Id, onGetCategoryById);
    yield takeEvery(ADD_CATEGORY, onAddCategory);
    yield takeEvery(EDIT_CATEGORY, onEditCategory);
    yield takeEvery(DELETE_CATEGORY, onDeleteCategory);
}

export default CategorySaga;
