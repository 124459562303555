import { takeEvery, put, call } from "redux-saga/effects";
// Continent Redux States
import { GET_STATE, ADD_STATE, EDIT_STATE, DELETE_STATE, GET_STATE_BY_Id } from "./actionTypes";
import { listState, listStateById } from "./actions";
import * as helpers from "../../../helpers/backend_Helper";
import toastr from "toastr";

function* onGetState() {
    try {
        const response = yield call(helpers.getState);
        yield put(listState(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onGetStateById({ payload: id }) {
    try {
        const response = yield call(helpers.getStateById, id);
        yield put(listStateById(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onAddState({ payload: event }) {
    try {
        const response = yield call(helpers.addState, event);
        if (response.status === 1) {
            yield call(onGetState);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onEditState({ payload: { event, id } }) {
    try {
        const response = yield call(helpers.editState, id, event);
        if (response.status === 1) {
            yield call(onGetState);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onDeleteState({ payload: event }) {
    try {
        const response = yield call(helpers.deleteState, event);
        if (response.status === 1) {
            yield call(onGetState);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* StateSaga() {
    yield takeEvery(GET_STATE, onGetState);
    yield takeEvery(GET_STATE_BY_Id, onGetStateById);
    yield takeEvery(ADD_STATE, onAddState);
    yield takeEvery(EDIT_STATE, onEditState);
    yield takeEvery(DELETE_STATE, onDeleteState);
}

export default StateSaga;
