//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";

//PRODUCTS
export const GET_PRODUCTS = "/products";
export const GET_PRODUCTS_DETAIL = "/product";

//SHOPS
export const GET_SHOPS = "/shops";

//ORDERS
export const GET_ORDERS = "/orders";

//CART DATA
export const GET_CART_DATA = "/cart";
export const ADD_NEW_ORDER = "/add/order";
export const UPDATE_ORDER = "/update/order";
export const DELETE_ORDER = "/delete/order";

//CUSTOMERS
export const GET_CUSTOMERS = "/customers";

//CHATS
export const GET_CHATS = "/chats";
export const GET_GROUPS = "/groups";
export const GET_CONTACTS = "/contacts";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "/add/messages";

//Continent
export const GET_CONTINENT = "/continent/get";
export const ADD_CONTINENT = "/continent/add";
export const EDIT_CONTINENT = "/continent/edit";
export const DELETE_CONTINENT = "/continent/delete";

//Country
export const GET_COUNTRY = "/country";
export const ADD_COUNTRY = "/country/add";
export const EDIT_COUNTRY = "/country/edit";
export const DELETE_COUNTRY = "/country/delete";

//State
export const GET_STATE = "/state";
export const ADD_STATE = "/state/add";
export const EDIT_STATE = "/state/edit";
export const DELETE_STATE = "/state/delete";

//City
export const GET_CITY = "/city";
export const ADD_CITY = "/city/add";
export const EDIT_CITY = "/city/edit";
export const DELETE_CITY = "/city/delete";

//Venue
export const GET_VENUE = "/venue";
export const ADD_VENUE = "/venue/add";
export const EDIT_VENUE = "/venue/edit";
export const DELETE_VENUE = "/venue/delete";

//Sport
export const GET_SPORT = "/sport";
export const ADD_SPORT = "/sport/add";
export const EDIT_SPORT = "/sport/edit";
export const DELETE_SPORT = "/sport/delete";

//Event
export const GET_EVENT = "/event";
export const ADD_EVENT = "/event/add";
export const EDIT_EVENT = "/event/edit";
export const DELETE_EVENTS = "/event/delete";

//Session
export const GET_SESSION = "/season";
export const ADD_SESSION = "/season/add";
export const EDIT_SESSION = "/season/edit";
export const DELETE_SESSION = "/season/delete";

//Team
export const GET_TEAM = "/team";
export const ADD_TEAM = "/team/add";
export const EDIT_TEAM = "/team/edit";
export const DELETE_TEAM = "/team/delete";

//Schedule
export const GET_SCHEDULE = "/schedule";
export const ADD_SCHEDULE = "/schedule/add";
export const EDIT_SCHEDULE = "/schedule/edit";
export const DELETE_SCHEDULE = "/schedule/delete";

//Category
export const GET_CATEGORY = "/category";
export const ADD_CATEGORY = "/category/add";
export const EDIT_CATEGORY = "/category/edit";
export const DELETE_CATEGORY = "/category/delete";

//Blog
export const GET_ARTICLE = "/blog";
export const ADD_ARTICLE = "/blog/add";
export const EDIT_ARTICLE = "/blog/edit";
export const DELETE_ARTICLE = "/blog/delete";

//Event
export const GET_GROUP = "/group";
export const ADD_GROUP = "/group/add";
export const EDIT_GROUP = "/group/edit";
export const DELETE_GROUPS = "/group/delete";

//Player
export const GET_PLAYER = "/player";
export const ADD_PLAYER = "/player/add";
export const EDIT_PLAYER = "/player/edit";
export const DELETE_PLAYERS = "/player/delete";
export const GET_PLAYER_CATEGORY = "/playercategory";
export const ADD_PLAYER_CATEGORY = "/playercategory/add";
export const EDIT_PLAYER_CATEGORY = "/playercategory/edit";
export const DELETE_PLAYER_CATEGORY = "/playercategory/delete";