import React, { useEffect, useMemo, useState } from "react";
import {
      Table,
      Row,
      Col,
      Card,
      CardBody,
      Container,
      Button,
      Label,
      Input,
} from "reactstrap";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import toastr from "toastr";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as Actions from "../../store/actions";
import Select from "react-select";
import moment from "moment";

const ScheduleAction = (props) => {
      const { event, session, team, venue, sport, group } = props;
      const [breadcrumbItems] = useState([{ title: "Schedule", link: "#" }])
      const isEdit = useMemo(() => props?.location?.state?.editItem, [props])
      const [scheduleData, setScheduleData] = useState({
            datetime: moment().format('YYYY-MM-DDTHH:mm:ss'),
            details: "",
            event: null,
            teamA: null,
            teamB: null,
            venue: null,
            season: null,
            sport: null,
            matchNumber: "",
      });

      useEffect(() => {
            props.getEvent();
            props.getTeam();
            props.getVenue();
            props.getSport();
            props.getGroup();

            if (props?.location?.state?.editItem) {
                  console.log(isEdit, '---')
                  if (props.location.state.editItem?.event?._id) {
                        props.getSessionById(props.location.state.editItem.event._id);
                  }
                  setScheduleData(prev => ({
                        ...prev,
                        datetime: moment(props.location.state.editItem?.datetime).format('YYYY-MM-DDTHH:mm:ss'),
                        details: props.location.state.editItem?.details || '',
                        event: props.location.state.editItem?.event || null,
                        teamA: props.location.state.editItem?.teamA || null,
                        teamB: props.location.state.editItem?.teamB || null,
                        venue: props.location.state.editItem?.venue || null,
                        season: props.location.state.editItem?.season || null,
                        sport: props.location.state.editItem?.sport || null,
                        matchNumber: props.location.state.editItem?.matchnumber || '',
                        group: props.location.state.editItem?.group || null,
                  }))
            }

            return () => {
                  props.clearAllSelect();
            };
      }, [])

      const handleInputChange = (name, value) => {
            setScheduleData((Value) => ({ ...Value, [name]: value }));
            if (name === 'event' && JSON.stringify(value) !== JSON.stringify(scheduleData.event)) {
                  props.getSessionById(value._id);
                  handleInputChange('session', null);
            }
      }

      const handleSubmit = async (e) => {
            if (!scheduleData.datetime && toastr.warning("Please Enter Date Time", "")) return;
            if (!scheduleData.details && toastr.warning("Please Enter Details", "")) return;
            if (!scheduleData.event && toastr.warning("Please Select Event", "")) return;
            if (!scheduleData.teamA && toastr.warning("Please Select teamA", "")) return;
            if (!scheduleData.teamB && toastr.warning("Please Select teamB", "")) return;
            if (!scheduleData.venue && toastr.warning("Please Select Venue", "")) return;
            if (!scheduleData.season && toastr.warning("Please Select Season", "")) return;
            if (!scheduleData.sport && toastr.warning("Please Enter Address", "")) return;
            if (!scheduleData.matchNumber && toastr.warning("Please Enter Match Number", "")) return;
            if (!scheduleData.group && toastr.warning("Please Select Group", "")) return;

            if (isEdit) {
                  props.editSchedule({
                        datetime: scheduleData.datetime,
                        details: scheduleData.details,
                        event: scheduleData.event._id,
                        teamA: scheduleData.teamA._id,
                        teamB: scheduleData.teamB._id,
                        venue: scheduleData.venue._id,
                        season: scheduleData.season._id,
                        sport: scheduleData.sport._id,
                        matchnumber: scheduleData.matchNumber,
                        group: scheduleData.group._id,
                  }, isEdit._id);
            } else {
                  props.addSchedule({
                        datetime: scheduleData.datetime,
                        details: scheduleData.details,
                        event: scheduleData.event._id,
                        teamA: scheduleData.teamA._id,
                        teamB: scheduleData.teamB._id,
                        venue: scheduleData.venue._id,
                        season: scheduleData.season._id,
                        sport: scheduleData.sport._id,
                        matchnumber: scheduleData.matchNumber,
                        group: scheduleData.group._id,
                  });
            }
            props.history.replace('/schedule')
      };

      return (
            <React.Fragment>
                  <div className="page-content">
                        <Container fluid>
                              <Breadcrumbs title={!isEdit ? 'Add New Schedule' : 'Edit Schedule'} breadcrumbItems={breadcrumbItems} />
                              <Card>
                                    <CardBody>
                                          <Row>
                                                <Col lg={4}>
                                                      <div className="mb-3">
                                                            <Label className="form-label">Date Time</Label>
                                                            <Input type="datetime-local" defaultValue={moment().format('YYYY-MM-DDTHH:mm:ss')} onChange={(e) => handleInputChange('datetime', e.target.value)} />
                                                      </div>
                                                </Col>
                                                <Col lg={4}>
                                                      <div className="mb-3">
                                                            <Label className="form-label">Event</Label>
                                                            <Select
                                                                  classNamePrefix="select2-selection"
                                                                  placeholder="Select Event"
                                                                  value={scheduleData['event']}
                                                                  onChange={(value) => handleInputChange('event', value)}
                                                                  options={event}
                                                                  getOptionLabel={(option) => option.name}
                                                                  getOptionValue={(option) => option?._id || option}
                                                            />
                                                      </div>
                                                </Col>
                                                <Col lg={4}>
                                                      <div className="mb-3">
                                                            <Label className="form-label">Session</Label>
                                                            <Select
                                                                  classNamePrefix="select2-selection"
                                                                  placeholder="Select Session"
                                                                  value={scheduleData['season']}
                                                                  onChange={(value) => handleInputChange('season', value)}
                                                                  options={session}
                                                                  getOptionLabel={(option) => option.name}
                                                                  getOptionValue={(option) => option?._id || option}
                                                            />
                                                      </div>
                                                </Col>
                                          </Row>
                                          <Row>
                                                <Col lg={4}>
                                                      <div className="mb-3">
                                                            <Label className="form-label">Team A</Label>
                                                            <Select
                                                                  classNamePrefix="select2-selection"
                                                                  placeholder="Select Team A"
                                                                  value={scheduleData['teamA']}
                                                                  onChange={(value) => handleInputChange('teamA', value)}
                                                                  options={team}
                                                                  getOptionLabel={(option) => option.name}
                                                                  getOptionValue={(option) => option?._id || option}
                                                            />
                                                      </div>
                                                </Col>
                                                <Col lg={4}>
                                                      <div className="mb-3">
                                                            <Label className="form-label">Team B</Label>
                                                            <Select
                                                                  classNamePrefix="select2-selection"
                                                                  placeholder="Select Team B"
                                                                  value={scheduleData['teamB']}
                                                                  onChange={(value) => handleInputChange('teamB', value)}
                                                                  options={team}
                                                                  getOptionLabel={(option) => option.name}
                                                                  getOptionValue={(option) => option?._id || option}
                                                            />
                                                      </div>
                                                </Col>
                                                <Col lg={4}>
                                                      <div className="mb-3">
                                                            <Label className="form-label">Venue</Label>
                                                            <Select
                                                                  classNamePrefix="select2-selection"
                                                                  placeholder="Select Venue"
                                                                  value={scheduleData['venue']}
                                                                  onChange={(value) => handleInputChange('venue', value)}
                                                                  options={venue}
                                                                  getOptionLabel={(option) => option.stadium_name}
                                                                  getOptionValue={(option) => option?._id || option}
                                                            />
                                                      </div>
                                                </Col>
                                          </Row>
                                          <Row>
                                                <Col lg={4}>
                                                      <div className="mb-3">
                                                            <Label className="form-label">Sport</Label>
                                                            <Select
                                                                  classNamePrefix="select2-selection"
                                                                  placeholder="Select Venue"
                                                                  value={scheduleData['sport']}
                                                                  onChange={(value) => handleInputChange('sport', value)}
                                                                  options={sport}
                                                                  getOptionLabel={(option) => option.name}
                                                                  getOptionValue={(option) => option?._id || option}
                                                            />
                                                      </div>
                                                </Col>
                                                <Col lg={4}>
                                                      <div className="mb-3">
                                                            <Label className="form-label">Match Number</Label>
                                                            <Input type="text" className="form-control" placeholder="Enter Match Number" required value={scheduleData['matchNumber']} onChange={(e) => handleInputChange('matchNumber', e.target.value)} />
                                                      </div>
                                                </Col>
                                                <Col lg={4}>
                                                      <div className="mb-3">
                                                            <Label className="form-label">Group</Label>
                                                            <Select
                                                                  classNamePrefix="select2-selection"
                                                                  placeholder="Select Group"
                                                                  value={scheduleData['group']}
                                                                  onChange={(value) => handleInputChange('group', value)}
                                                                  options={group}
                                                                  getOptionLabel={(option) => option.name}
                                                                  getOptionValue={(option) => option?._id || option}
                                                            />
                                                      </div>
                                                </Col>
                                                <Col lg={8}>
                                                      <div className="mb-3">
                                                            <Label className="form-label">Details</Label>
                                                            <textarea className="form-control" rows="2"
                                                                  required
                                                                  value={scheduleData['details']}
                                                                  onChange={(e) => handleInputChange('details', e.target.value)}
                                                            ></textarea>
                                                      </div>
                                                </Col>
                                          </Row>
                                          <Row>
                                                <Col lg={12}>
                                                      <Button onClick={handleSubmit} color="success" className="waves-effect waves-light me-1 float-end">
                                                            Submit
                                                      </Button>
                                                </Col>
                                          </Row>
                                    </CardBody>
                              </Card>
                        </Container>
                  </div>
            </React.Fragment>
      )
}

function mapDispatchToProps(dispatch) {
      return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = ({ Event, Session, Team, Venue, Sport, Group }) => {
      return {
            event: Event.event,
            session: Session.sessionById,
            team: Team.team,
            venue: Venue.venue,
            sport: Sport.sport,
            group: Group.group,
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleAction);