import React, { useEffect, useState } from "react";
import {
      Table,
      Row,
      Col,
      Card,
      CardBody,
      Container,
      Button,
} from "reactstrap";
import { Link } from "react-router-dom";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import IndiaLogo from "../../assets/images/flags/india.png";
import DeleteModal from "../Tables/deleteModal";
import moment from "moment";
import { bindActionCreators } from "redux";
import * as Actions from "../../store/actions";
import { connect } from "react-redux";

const Schedule = (props) => {
      const [breadcrumbItems] = useState([{ title: "Schedule", link: "#" }])
      const [isDeleteModal, setIsDeleteModal] = useState(false);
      const [selectedRow, setSelectedRow] = useState(null)

      useEffect(() => {
            props.getSchedule();
      }, [])

      const handleDeleteEvent = () => {
            props.deleteSchedule(selectedRow?._id || "");
            setIsDeleteModal(false);
            setSelectedRow(null)
      }

      return (
            <React.Fragment>
                  <div className="page-content">
                        <Container fluid>
                              <Breadcrumbs title="Schedule" breadcrumbItems={breadcrumbItems} />
                              <Row>
                                    <Col lg={12}>
                                          <Card>
                                                <CardBody>
                                                      <div className="d-flex  justify-content-between mb-2">
                                                            <h4 className="card-title">Schedule List</h4>
                                                            <Button onClick={() => props.history.push('/scheduleAction')} color="success" className="waves-effect waves-light me-1 float-end">
                                                                  Add New Schedule
                                                            </Button>
                                                      </div>

                                                      <div className="table-responsive team-table">
                                                            <Table className="table table-bordered table-hover">
                                                                  <thead className="table-light table-nowrap">
                                                                        <tr>
                                                                              <th>#</th>
                                                                              <th>Date Time</th>
                                                                              <th>Sport</th>
                                                                              <th>Event</th>
                                                                              <th>Session</th>
                                                                              <th>Team A</th>
                                                                              <th>Team B</th>
                                                                              <th>Group</th>
                                                                              <th>Venue</th>
                                                                              <th>Details</th>
                                                                              <th style={{ width: "100px" }}>Action</th>
                                                                        </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                        {props.schedule.map((x, i) => {
                                                                              return (
                                                                                    <tr key={String(i)}>
                                                                                          <th scope="row">{i + 1}</th>
                                                                                          <td>{moment(x?.datetime).format('MMMM Do YYYY, h:mm a')}</td>
                                                                                          <td>{x?.sport?.name}</td>
                                                                                          <td>{x?.event?.name}</td>
                                                                                          <td>{x?.season?.name}</td>
                                                                                          <td>{x?.teamA?.name}</td>
                                                                                          <td>{x?.teamB?.name}</td>
                                                                                          <td>{x?.group?.name}</td>
                                                                                          <td>{x?.venue?.stadium_address}</td>
                                                                                          <td>{x?.details}</td>
                                                                                          <td>
                                                                                                {/* <Link to="#" className="me-3 text-success">
                                                                                                      <i className="mdi mdi-eye font-size-18"></i>
                                                                                                </Link> */}
                                                                                                <Link to={{ pathname: '/scheduleAction', state: { editItem: x } }} className="me-3 text-primary">
                                                                                                      <i className="mdi mdi-pencil font-size-18"></i>
                                                                                                </Link>
                                                                                                <Link to="#" className="text-danger" onClick={() => { setIsDeleteModal(true); setSelectedRow(x) }}>
                                                                                                      <i className="mdi mdi-trash-can font-size-18"></i>
                                                                                                </Link>
                                                                                          </td>
                                                                                    </tr>
                                                                              )
                                                                        })}
                                                                  </tbody>
                                                            </Table>
                                                      </div>
                                                </CardBody>
                                          </Card>
                                    </Col>
                              </Row>
                        </Container>
                  </div>

                  <DeleteModal show={isDeleteModal} onDeleteClick={handleDeleteEvent} onCloseClick={() => setIsDeleteModal(false)} />
            </React.Fragment>
      )
}

function mapDispatchToProps(dispatch) {
      return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = ({ Schedule }) => {
      return {
            schedule: Schedule.schedule,
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);