import { LIST_EVENT, LIST_EVENT_BY_Id } from "./actionTypes";

const INIT_STATE = {
    event: [],
    eventById: [],
    error: {},
};

const Event = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_EVENT:
            return {
                ...state,
                event: action.payload,
            };
        case LIST_EVENT_BY_Id:
            return {
                ...state,
                eventById: action.payload,
            };
        default:
            return state;
    }
};

export default Event;
