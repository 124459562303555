import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication Module
import Account from './auth/register/reducer';
import Login from './auth/login/reducer';
import Forget from './auth/forgetpwd/reducer';

//Calendar 
import Calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer"


//ecommerce
import Ecommerce from "./e-commerce/reducer";

// Continent 
import Continent from "./location/Continent/reducer"
import Country from "./location/Country/reducer"
import State from "./location/State/reducer"
import City from "./location/City/reducer"
import Venue from "./location/Venue/reducer"

import Sport from "./Sports/reducer"

import Event from "./Event/reducer"

import Session from "./Session/reducer"

import Team from "./Team/reducer"

import Schedule from "./Schedule/reducer"

import Article from "./Article/reducer"

import Category from "./Category/reducer"

import Group from "./Group/reducer"

import Player from "./Player/reducer"

const rootReducer = combineReducers({
    // public
    Layout,
    // Authentication
    Account,
    Login,
    Forget,
    Calendar,
    Ecommerce,
    chat,
    Continent,
    Country,
    State,
    City,
    Venue,
    Sport,
    Event,
    Session,
    Team,
    Schedule,
    Article,
    Category,
    Group,
    Player,
});

export default rootReducer;