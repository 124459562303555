import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, Container, Button, Label, Modal, ModalHeader, ModalBody, Form, Input, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import toastr from "toastr";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as Actions from "../../store/actions";
import DeleteModal from "./deleteModal";
import UpdateContinent from "../../components/Modal/UpdateContinent";

class Continent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Location", link: "#" },
                { title: "Continent", link: "#" },
            ],
            continentList: [],
            isLoadContinent: false,
            deleteModal: false,
            name: "",
            selectedRow: null,
            updateData: null,
            showUpdateModal: false,
        };
    }

    componentDidMount() {
        this.props.getContinent();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { isModal } = this.state;
        if (prevState.isModal !== isModal && isModal) {
            this.setState({ name: "" });
        }
    }

    handleChange = (event) => {
        this.setState({ name: event.target.value });
    };

    handleSubmit = async (e) => {
        if (e) e.preventDefault();
        if (!this.state.name) {
            toastr.warning("Please Enter Continent", "");
            return;
        }
        this.props.addContinent({ name: this.state.name });
        this.setState({ isModal: !this.state.isModal });
    };

    handleDeleteEvent = async () => {
        this.props.deleteContinent(this.state.selectedRow?._id || "");
        this.setState({ deleteModal: false, selectedRow: null });
    };

    updateDataShow = (x) => {
        this.setState({
            updateData: x
        }, () => {
            this.setState({ showUpdateModal: true })
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Continent" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex  justify-content-between mb-2">
                                            <h4 className="card-title">Continent List</h4>
                                            <Button
                                                onClick={() => this.setState({ isModal: !this.state.modal })}
                                                color="success"
                                                className="waves-effect waves-light me-1 float-end"
                                            >
                                                Add New Continent
                                            </Button>
                                        </div>

                                        <div className="table-responsive">
                                            <Table className="table table-bordered table-hover">
                                                <thead className="table-light table-nowrap">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Continent</th>
                                                        <th style={{ width: "100px" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.props.continent.map((x, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <th scope="row">{index + 1}</th>
                                                                <td>{x?.name || ""}</td>
                                                                <td>
                                                                    <Link to="#" className="me-3 text-primary" onClick={() => { this.updateDataShow(x) }}>
                                                                        <i className="mdi mdi-pencil font-size-18"></i>
                                                                    </Link>
                                                                    <Link to="#" className="text-danger" onClick={() => this.setState({ deleteModal: true, selectedRow: x })}>
                                                                        <i className="mdi mdi-trash-can font-size-18"></i>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Modal
                    isOpen={this.state.isModal}
                    toggle={() => this.setState({ isModal: !this.state.isModal })}
                >
                    <ModalHeader toggle={() => this.setState({ isModal: !this.state.isModal })}>Create New Continent</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Label className="form-label" htmlFor="name">
                                            Continent
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="Continent"
                                            placeholder="Enter Continent"
                                            value={this.state.name}
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <ModalFooter className="pb-0">
                                        <Button type="button" color="light" onClick={() => this.setState({ isModal: !this.state.isModal })}>
                                            Close
                                        </Button>
                                        <Button type="submit" onClick={this.handleSubmit} color="primary">
                                            Submit
                                        </Button>
                                    </ModalFooter>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>

                <UpdateContinent
                    showModal={this.state.showUpdateModal}
                    closeModal={() => {
                        this.setState({ showUpdateModal: false })
                    }}
                    item={this.state.updateData}
                    {...this.props}
                />

                <DeleteModal show={this.state.deleteModal} onDeleteClick={this.handleDeleteEvent} onCloseClick={() => this.setState({ deleteModal: false })} />
            </React.Fragment>
        );
    }
}

// Continent.propTypes = {
//     continent: PropTypes.array,
//     onContinents: PropTypes.func,
//     onAddNewContinents: PropTypes.func,
// };

// const mapDispatchToProps = (dispatch) => ({
//     onContinents: () => dispatch(getContinent()),
//     onAddNewContinents: (event) => dispatch(addContinent(event)),
//     onDeleteContinents: (event) => dispatch(deleteContinent(event)),
//     onEditContinents: (event) => dispatch(deleteContinent(event)),

// });

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = ({ Continent }) => {
    return {
        continent: Continent.continent,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Continent);
