import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Table,
} from "reactstrap";
import { bindActionCreators } from 'redux';

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { API_URL_IMAGE } from "../../helpers/api_helper";
import * as Actions from "../../store/actions";
import DeleteModal from "../Tables/deleteModal";

const Player = (props) => {
    const [breadcrumbItems] = useState([{ title: "Player", link: "#" }])
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    useEffect(() => {
        props.getPlayer();
    }, []);

    const handleDeleteEvent = () => {
        props.deletePlayer(selectedRow?._id || "");
        setIsDeleteModal(false);
        setSelectedRow(null)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Player" breadcrumbItems={breadcrumbItems} />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex  justify-content-between mb-2">
                                        <h4 className="card-title">Player List</h4>
                                        <Button onClick={() => props.history.push('/playerAction')} color="success" className="waves-effect waves-light me-1 float-end">
                                            Add New Player
                                        </Button>
                                    </div>

                                    <div className="table-responsive player-table">
                                        <Table className="table table-bordered table-hover" >
                                            <thead className="table-light table-nowrap">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Player Photo</th>
                                                    <th>Name</th>
                                                    <th>Team Name</th>
                                                    <th>Category</th>
                                                    <th className="address">Address</th>
                                                    <th>Country</th>
                                                    <th>State</th>
                                                    <th>City</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.player.map((x, i) => {
                                                    return (
                                                        <tr key={String(i)}>
                                                            <th scope="row">{i + 1}</th>
                                                            <td><img src={x?.image} alt='' /></td>
                                                            <td>{x?.name}</td>
                                                            <td>{x?.team?.name}</td>
                                                            <td>{x?.playercategory?.name}</td>
                                                            <td>{x?.address}</td>
                                                            <td>{x?.country?.name}</td>
                                                            <td>{x?.state?.name}</td>
                                                            <td>{x?.city?.name}</td>
                                                            <td>
                                                                <Link to={{ pathname: '/playerAction', state: { editItem: x } }} className="me-3 text-primary">
                                                                    <i className="mdi mdi-pencil font-size-18"></i>
                                                                </Link>
                                                                <Link to="#" className="text-danger" onClick={() => { setIsDeleteModal(true); setSelectedRow(x) }}>
                                                                    <i className="mdi mdi-trash-can font-size-18"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* {isAddModal &&
                <VenueModal
                    isAddModal={isAddModal}
                    closeModal={() => { setSelectedRow(null); setIsAddModal(false) }}
                    item={selectedRow}
                    {...props}
                />
            } */}

            <DeleteModal show={isDeleteModal} onDeleteClick={handleDeleteEvent} onCloseClick={() => setIsDeleteModal(false)} />
        </React.Fragment>
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = ({ Player }) => {
    return {
        player: Player.player,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Player);