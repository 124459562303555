import { LIST_GROUP, LIST_GROUP_BY_Id } from "./actionTypes";

const INIT_STATE = {
    group: [],
    groupById: [],
    error: {},
};

const Group = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_GROUP:
            return {
                ...state,
                group: action.payload,
            };
        case LIST_GROUP_BY_Id:
            return {
                ...state,
                groupById: action.payload,
            };
        default:
            return state;
    }
};

export default Group;
