import { takeEvery, put, call } from "redux-saga/effects";
// Continent Redux States
import { GET_VENUE, LIST_VENUE, ADD_VENUE, EDIT_VENUE, DELETE_VENUE, GET_VENUE_BY_Id } from "./actionTypes";
import { listVenue, listVenueById } from "./actions";
import * as helpers from "../../../helpers/backend_Helper";
import toastr from "toastr";

function* onGetVenue() {
    try {
        const response = yield call(helpers.getVenue);
        yield put(listVenue(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onGetVenueById({ payload: id }) {
    try {
        const response = yield call(helpers.getVenueById, id);
        yield put(listVenueById(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onAddVenue({ payload: event }) {
    try {
        const response = yield call(helpers.addVenue, event);
        if (response.status === 1) {
            yield call(onGetVenue);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onEditVenue({ payload: { event, id } }) {
    try {
        const response = yield call(helpers.editVenue, id, event);
        if (response.status === 1) {
            yield call(onGetVenue);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onDeleteVenue({ payload: event }) {
    try {
        const response = yield call(helpers.deleteVenue, event);
        if (response.status === 1) {
            yield call(onGetVenue);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* VenueSaga() {
    yield takeEvery(GET_VENUE, onGetVenue);
    yield takeEvery(GET_VENUE_BY_Id, onGetVenueById);
    yield takeEvery(ADD_VENUE, onAddVenue);
    yield takeEvery(EDIT_VENUE, onEditVenue);
    yield takeEvery(DELETE_VENUE, onDeleteVenue);
}

export default VenueSaga;
