import { LIST_PLAYER, LIST_PLAYER_BY_Id, LIST_PLAYER_CATEGORY } from "./actionTypes";

const INIT_STATE = {
    player: [],
    playerById: [],
    playerCategory: [],
    error: {},
};

const Player = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_PLAYER:
            return {
                ...state,
                player: action.payload,
            };
        case LIST_PLAYER_BY_Id:
            return {
                ...state,
                playerById: action.payload,
            };
        case LIST_PLAYER_CATEGORY:
            return {
                ...state,
                playerCategory: action.payload,
            };
        default:
            return state;
    }
};

export default Player;
