import React, { Component, useEffect, useState } from "react";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as Actions from "../../store/actions";
import DeleteModal from "./deleteModal";
import CountryModal from "../../components/Modal/CountryModal";

function Country(props) {
    const [breadcrumbItems] = useState([
        { title: "Location", link: "#" },
        { title: "Country", link: "#" },
    ])
    const [isModal, setIsModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)

    useEffect(() => {
        props.getContinent();
        props.getCountry();
    }, []);

    const handleDeleteEvent = async () => {
        props.deleteCountry(selectedRow?._id || "");
        setDeleteModal(false)
        setSelectedRow(null)
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Country" breadcrumbItems={breadcrumbItems} />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex  justify-content-between mb-2">
                                        <h4 className="card-title">Country List</h4>
                                        <Button
                                            onClick={() => setIsModal(true)}
                                            color="success"
                                            className="waves-effect waves-light me-1 float-end"
                                        >
                                            Add New County
                                        </Button>
                                    </div>

                                    <div className="table-responsive">
                                        <Table className="table table-bordered table-hover">
                                            <thead className="table-light table-nowrap">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Continent</th>
                                                    <th>Country</th>
                                                    <th style={{ width: "100px" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.country.map((x, i) => {
                                                    return (
                                                        <tr key={String(i)}>
                                                            <th scope="row">{i + 1}</th>
                                                            <td>{x?.continent?.name}</td>
                                                            <td>{x?.name}</td>
                                                            <td>
                                                                <Link to="#" className="me-3 text-primary" onClick={() => { setSelectedRow(x); setIsModal(true); }}>
                                                                    <i className="mdi mdi-pencil font-size-18"></i>
                                                                </Link>
                                                                <Link to="#" className="text-danger" onClick={() => { setDeleteModal(true); setSelectedRow(x) }}>
                                                                    <i className="mdi mdi-trash-can font-size-18"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {isModal &&
                <CountryModal
                    showModal={isModal}
                    closeModal={() => { setSelectedRow(null); setIsModal(false) }}
                    item={selectedRow}
                    {...props}
                />
            }

            <DeleteModal show={deleteModal} onDeleteClick={handleDeleteEvent} onCloseClick={() => setDeleteModal(false)} />
        </React.Fragment>
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = ({ Continent, Country }) => {
    return {
        continent: Continent.continent,
        country: Country.country
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Country);