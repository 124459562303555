import React, { useEffect, useMemo, useState } from "react";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Button,
    Label,
    Input,
} from "reactstrap";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import toastr from "toastr";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as Actions from "../../store/actions";
import Select from "react-select";
import moment from "moment";

import { useQuill } from "react-quilljs";
import 'quill/dist/quill.snow.css';

const VenueAction = (props) => {
    const { continent, country, state, city, sport } = props;
    const [breadcrumbItems] = useState([{ title: "Venue", link: "#" }])
    const isEdit = useMemo(() => props?.location?.state?.editItem, [props])

    const [selectedContinent, setSelectedContinent] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [inputValue, setInputValue] = useState({
        pinCode: '',
        stadiumName: '',
        address: '',
        stadiumCategory: null,
        image: '',
        description: ''
    });

    useEffect(() => {
        props.getContinent();
        props.getSport();

        if (props?.location?.state?.editItem) {
            console.log(isEdit, '---')
            setSelectedContinent(props?.location?.state?.editItem?.continent);
            if (props?.location?.state?.editItem?.continent?._id) {
                props.getCountryById(props.location.state.editItem.continent._id);
            }
            setSelectedCountry(props?.location?.state?.editItem?.country);
            if (props?.location?.state?.editItem?.country?._id) {
                props.getStateById(props.location.state.editItem.country._id);
            }
            setSelectedState(props?.location?.state?.editItem?.state);
            if (props?.location?.state?.editItem?.state?._id) {
                props.getCityById(props.location.state.editItem.state._id);
            }
            setSelectedCity(props?.location?.state?.editItem?.city);
            setInputValue(prev => ({
                ...prev,
                pinCode: props?.location?.state?.editItem?.stadium_pincode || '',
                stadiumName: props?.location?.state?.editItem?.stadium_name || '',
                address: props?.location?.state?.editItem?.stadium_address || '',
                description: props?.location?.state?.editItem?.description || '',
                stadiumCategory: props?.location?.state?.editItem?.sport || null
            }))
        }
        return () => {
            props.clearAllSelect();
        };
    }, []);

    const { quill, quillRef } = useQuill();
    useEffect(() => {
        if (quill) {
            quill.on('text-change', (delta, oldDelta, source) => {
                // console.log(quill.getText()); // Get text only
                // console.log(quill.getContents()); // Get delta contents
                // console.log(quill.root.innerHTML); // Get innerHTML using quill
                // console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
                handleChange('description', quill.root.innerHTML)
            });
            if (quill) {
                quill.clipboard.dangerouslyPasteHTML(inputValue.description);
            }
        }
    }, [quill]);

    const handleSelectGroup = (name, value) => {
        if (name == 'continent') {
            if (JSON.stringify(value) !== JSON.stringify(selectedContinent)) {
                props.getCountryById(value._id);
                setSelectedCountry(null);
                setSelectedState(null);
                setSelectedCity(null);
            }
            setSelectedContinent(value);
        } else if (name == 'country') {
            if (JSON.stringify(value) !== JSON.stringify(selectedCountry)) {
                props.getStateById(value._id);
                setSelectedState(null);
                setSelectedCity(null);
            }
            setSelectedCountry(value);
        } else if (name == 'state') {
            if (JSON.stringify(value) !== JSON.stringify(selectedState)) {
                props.getCityById(value._id);
                setSelectedCity(null);
            }
            setSelectedState(value);
        } else if (name == 'city') {
            setSelectedCity(value);
        }
    }

    const handleChange = (name, value) => {
        setInputValue(prev => ({ ...prev, [name]: value }))
    };

    const handleSubmit = async (e) => {
        console.log(inputValue)
        if (!inputValue.image && !isEdit && toastr.warning("Please Enter Image", "")) return;
        if (!inputValue.stadiumName && toastr.warning("Please Enter Stadium Name", "")) return;
        if (!selectedContinent && toastr.warning("Please Select Continent", "")) return;
        if (!selectedCountry && toastr.warning("Please Select Country", "")) return;
        if (!selectedState && toastr.warning("Please Select State", "")) return;
        if (!selectedCity && toastr.warning("Please Select City", "")) return;
        if (!inputValue.pinCode && toastr.warning("Please Enter Pin Code", "")) return;
        if (!inputValue.stadiumName && toastr.warning("Please Enter Stadium", "")) return;
        if (!inputValue.address && toastr.warning("Please Enter Address", "")) return;
        if (!inputValue.stadiumCategory && toastr.warning("Please Select Category", "")) return;
        if (!inputValue.description && toastr.warning("Please Enter Description", "")) return;

        let formData = new FormData();
        if (!isEdit || inputValue.image) {
            formData.append('image', inputValue.image);
        }
        formData.append('stadium_name', inputValue.stadiumName);
        formData.append('stadium_address', inputValue.address);
        formData.append('stadium_pincode', inputValue.pinCode);
        formData.append('continent', selectedContinent?._id);
        formData.append('country', selectedCountry?._id);
        formData.append('state', selectedState?._id);
        formData.append('city', selectedCity?._id);
        formData.append('sport', inputValue.stadiumCategory?._id);
        formData.append('description', inputValue?.description);

        if (isEdit) {
            props.editVenue(formData, isEdit._id);
        } else {
            await props.addVenue(formData);
        }
        props.history.replace('/venue')
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={!isEdit ? 'Add New Venue' : 'Edit Venue'} breadcrumbItems={breadcrumbItems} />

                    <Row>
                        <Col lg="9">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="email">Stadium Name</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Stadium"
                                                    required
                                                    value={inputValue['stadiumName']}
                                                    onChange={(e) => handleChange('stadiumName', e.target.value)}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="email">Continent</Label>
                                                <Select
                                                    value={selectedContinent}
                                                    onChange={(value) => handleSelectGroup('continent', value)}
                                                    options={continent}
                                                    classNamePrefix="select2-selection"
                                                    placeholder='Select Continent'
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option?._id || option}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="email">Country</Label>
                                                <Select
                                                    value={selectedCountry}
                                                    onChange={(value) => handleSelectGroup('country', value)}
                                                    options={country}
                                                    classNamePrefix="select2-selection"
                                                    placeholder='Select Country'
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option?._id || option}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="email">State</Label>
                                                <Select
                                                    value={selectedState}
                                                    onChange={(value) => handleSelectGroup('state', value)}
                                                    options={state}
                                                    classNamePrefix="select2-selection"
                                                    placeholder='Select State'
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option?._id || option}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="email">City</Label>
                                                <Select
                                                    value={selectedCity}
                                                    onChange={(value) => handleSelectGroup('city', value)}
                                                    options={city}
                                                    classNamePrefix="select2-selection"
                                                    placeholder='Select City'
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option?._id || option}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="email">Pin Code</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Pin Code"
                                                    required
                                                    value={inputValue['pinCode']}
                                                    onChange={(e) => handleChange('pinCode', e.target.value)}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="subject">Address</Label>
                                                <textarea
                                                    className="form-control"
                                                    id="subject"
                                                    rows="3"
                                                    required
                                                    value={inputValue['address']}
                                                    onChange={(e) => handleChange('address', e.target.value)}
                                                ></textarea>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">Description</Label>
                                                <div ref={quillRef} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <Button onClick={handleSubmit} color="success" className="waves-effect waves-light me-1 float-end">
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3">
                            <Card className="checkout-order-summary">
                                <CardBody>
                                    <div className="mb-3">
                                        <Label className="form-label">Stadium Image</Label>
                                        <Input type="file" className="form-control" placeholder="Enter Stadium Image" required accept="image/*" onChange={(e) => handleChange('image', e.target.files[0])} />
                                    </div>
                                    <div className="mb-3">
                                        <Label className="form-label">Stadium Category</Label>
                                        <Select
                                            value={inputValue['stadiumCategory']}
                                            onChange={(value) => handleChange('stadiumCategory', value)}
                                            options={sport}
                                            classNamePrefix="select2-selection"
                                            placeholder='Select Stadium Category'
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option?._id || option}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = ({ Continent, Country, State, City, Venue, Sport }) => {
    return {
        continent: Continent.continent,
        country: Country.countryById,
        state: State.stateById,
        city: City.cityById,
        venue: Venue.venue,
        sport: Sport.sport,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VenueAction);