import axios from "axios";
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

// get Continents
export const getContinents = () => get(url.GET_CONTINENT);
export const addContinents = (event) => post(url.ADD_CONTINENT, event);
export const editContinents = (id, event) => put(url.EDIT_CONTINENT + '/' + id, event);
export const deleteContinents = (event) => del(`${url.DELETE_CONTINENT}/${event}`);


// get Country
export const getCountry = () => get(url.GET_COUNTRY);
export const getCountryById = (id) => get(url.GET_COUNTRY + (id ? `/${id}` : ''));
export const addCountry = (event) => post(url.ADD_COUNTRY, event);
export const editCountry = (id, event) => put(url.EDIT_COUNTRY + '/' + id, event);
export const deleteCountry = (event) => del(`${url.DELETE_COUNTRY}/${event}`);

// get State
export const getState = () => get(url.GET_STATE);
export const getStateById = (id) => get(url.GET_STATE + (id ? `/${id}` : ''));
export const addState = (event) => post(url.ADD_STATE, event);
export const editState = (id, event) => put(url.EDIT_STATE + '/' + id, event);
export const deleteState = (event) => del(`${url.DELETE_STATE}/${event}`);

// get City
export const getCity = () => get(url.GET_CITY);
export const getCityById = (id) => get(url.GET_CITY + (id ? `/${id}` : ''));
export const addCity = (event) => post(url.ADD_CITY, event);
export const editCity = (id, event) => put(url.EDIT_CITY + '/' + id, event);
export const deleteCity = (event) => del(`${url.DELETE_CITY}/${event}`);

// get Venue
export const getVenue = () => get(url.GET_VENUE);
export const getVenueById = (id) => get(url.GET_VENUE + (id ? `/${id}` : ''));
export const addVenue = (event) => post(url.ADD_VENUE, event);
export const editVenue = (id, event) => put(url.EDIT_VENUE + '/' + id, event);
export const deleteVenue = (event) => del(`${url.DELETE_VENUE}/${event}`);

// get Sport
export const getSport = () => get(url.GET_SPORT);
export const getSportById = (id) => get(url.GET_SPORT + (id ? `/${id}` : ''));
export const addSport = (event) => post(url.ADD_SPORT, event);
export const editSport = (id, event) => put(url.EDIT_SPORT + '/' + id, event);
export const deleteSport = (event) => del(`${url.DELETE_SPORT}/${event}`);

// get Event
export const getEvent = () => get(url.GET_EVENT);
export const getEventById = (id) => get(url.GET_EVENT + (id ? `/${id}` : ''));
export const addEvent = (event) => post(url.ADD_EVENT, event);
export const editEvent = (id, event) => put(url.EDIT_EVENT + '/' + id, event);
export const deleteEvents = (event) => del(`${url.DELETE_EVENTS}/${event}`);

// get Session
export const getSession = () => get(url.GET_SESSION);
export const getSessionById = (id) => get(url.GET_SESSION + (id ? `/${id}` : ''));
export const addSession = (event) => post(url.ADD_SESSION, event);
export const editSession = (id, event) => put(url.EDIT_SESSION + '/' + id, event);
export const deleteSession = (event) => del(`${url.DELETE_SESSION}/${event}`);

// get Team
export const getTeam = () => get(url.GET_TEAM);
export const getTeamById = (id) => get(url.GET_TEAM + (id ? `/${id}` : ''));
export const addTeam = (event) => post(url.ADD_TEAM, event, { headers: { 'Content-Type': 'multipart/form-data' } });
export const editTeam = (id, event) => put(url.EDIT_TEAM + '/' + id, event, { headers: { 'Content-Type': 'multipart/form-data' } });
export const deleteTeam = (event) => del(`${url.DELETE_TEAM}/${event}`);

// get Schedule
export const getSchedule = () => get(url.GET_SCHEDULE);
export const getScheduleById = (id) => get(url.GET_SCHEDULE + (id ? `/${id}` : ''));
export const addSchedule = (event) => post(url.ADD_SCHEDULE, event);
export const editSchedule = (id, event) => put(url.EDIT_SCHEDULE + '/' + id, event);
export const deleteSchedule = (event) => del(`${url.DELETE_SCHEDULE}/${event}`);

// get Category
export const getCategory = () => get(url.GET_CATEGORY);
export const getCategoryById = (id) => get(url.GET_CATEGORY + (id ? `/${id}` : ''));
export const addCategory = (event) => post(url.ADD_CATEGORY, event);
export const editCategory = (id, event) => put(url.EDIT_CATEGORY + '/' + id, event);
export const deleteCategory = (event) => del(`${url.DELETE_CATEGORY}/${event}`);

// get Blog
export const getArticle = () => get(url.GET_ARTICLE);
export const getArticleById = (id) => get(url.GET_ARTICLE + (id ? `/${id}` : ''));
export const addArticle = (event) => post(url.ADD_ARTICLE, event, { headers: { 'Content-Type': 'multipart/form-data' } });
export const editArticle = (id, event) => put(url.EDIT_ARTICLE + '/' + id, event, { headers: { 'Content-Type': 'multipart/form-data' } });
export const deleteArticle = (event) => del(`${url.DELETE_ARTICLE}/${event}`);

// get Group
export const getGroup = () => get(url.GET_GROUP);
export const getGroupById = (id) => get(url.GET_GROUP + (id ? `/${id}` : ''));
export const addGroup = (event) => post(url.ADD_GROUP, event);
export const editGroup = (id, event) => put(url.EDIT_GROUP + '/' + id, event);
export const deleteGroups = (event) => del(`${url.DELETE_GROUPS}/${event}`);

// get PLAYER
export const getPlayer = () => get(url.GET_PLAYER);
export const getPlayeryId = (id) => get(url.GET_PLAYER + (id ? `/${id}` : ''));
export const addPlayer = (event) => post(url.ADD_PLAYER, event);
export const editPlayer = (id, event) => put(url.EDIT_PLAYER + '/' + id, event);
export const deletePlayers = (event) => del(`${url.DELETE_PLAYERS}/${event}`);
// -----
export const getPlayerCategory = () => get(url.GET_PLAYER_CATEGORY);
export const getPlayerCategoryById = (id) => get(url.GET_PLAYER_CATEGORY + (id ? `/${id}` : ''));
export const addPlayerCategory = (event) => post(url.ADD_PLAYER_CATEGORY, event);
export const editPlayerCategory = (id, event) => put(url.EDIT_PLAYER_CATEGORY + '/' + id, event);
export const deletePlayerCategory = (event) => del(`${url.DELETE_PLAYER_CATEGORY}/${event}`);