import { takeEvery, put, call } from "redux-saga/effects";
// Continent Redux States
import { GET_SPORT, ADD_SPORT, EDIT_SPORT, DELETE_SPORT, GET_SPORT_BY_Id } from "./actionTypes";
import { listSport, listSportById } from "./actions";
import * as helpers from "../../helpers/backend_Helper";
import toastr from "toastr";

function* onGetSport() {
    try {
        const response = yield call(helpers.getSport);
        yield put(listSport(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onGetSportById({ payload: id }) {
    try {
        const response = yield call(helpers.getSportById, id);
        yield put(listSportById(response.data));
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onAddSport({ payload: event }) {
    try {
        const response = yield call(helpers.addSport, event);
        if (response.status === 1) {
            yield call(onGetSport);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onEditSport({ payload: { event, id } }) {
    try {
        const response = yield call(helpers.editSport, id, event);
        if (response.status === 1) {
            yield call(onGetSport);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* onDeleteSport({ payload: event }) {
    try {
        const response = yield call(helpers.deleteSport, event);
        if (response.status === 1) {
            yield call(onGetSport);
            toastr.success(response.message, "");
        } else {
            toastr.error(response.message, "");
        }
    } catch (error) {
        toastr.error(error, "");
    }
}

function* SportSaga() {
    yield takeEvery(GET_SPORT, onGetSport);
    yield takeEvery(GET_SPORT_BY_Id, onGetSportById);
    yield takeEvery(ADD_SPORT, onAddSport);
    yield takeEvery(EDIT_SPORT, onEditSport);
    yield takeEvery(DELETE_SPORT, onDeleteSport);
}

export default SportSaga;
