import React, { useEffect, useState } from 'react';
import {
      Row,
      Col,
      Button,
      Label,
      Modal,
      ModalHeader,
      ModalBody,
      Form,
      ModalFooter,
      Input,
} from "reactstrap";
import toastr from "toastr";

function SportsModal(props) {
      const { showModal, closeModal } = props;
      const [name, setName] = useState('');
      const [id, setId] = useState(null);

      useEffect(() => {
            if (props.item) {
                  console.log(props.item, '-----');
                  setName(props?.item?.name);
                  setId(props?.item?._id);
            }
      }, [props.showModal]);

      const handleChange = (event) => {
            setName(event.target.value);
      };

      const handleSubmit = async (e) => {
            if (!name && toastr.warning("Please Enter Sport", "")) return;

            if (props.item) {
                  props.editSport({ name: name }, id);
            } else {
                  props.addSport({ name: name });
            }
            props.closeModal();
      };

      return (
            <Modal
                  isOpen={showModal}
                  toggle={() => closeModal()}
            >
                  <ModalHeader
                        toggle={() => closeModal()}
                  >Add New Sports</ModalHeader>
                  <ModalBody>
                        <Form>
                              <Row>
                                    <Col lg={12}>
                                          <div className="mb-3">
                                                <Label className="form-label" htmlFor="name">
                                                      Sports Name
                                                </Label>
                                                <Input
                                                      type="text"
                                                      className="form-control"
                                                      id="name"
                                                      placeholder="Enter Sport"
                                                      value={name}
                                                      onChange={handleChange}
                                                />
                                          </div>
                                    </Col>
                              </Row>
                              <Row>
                                    <Col lg={12}>
                                          <ModalFooter className="pb-0">
                                                <Button color="light" onClick={() => closeModal()}>
                                                      Close
                                                </Button>
                                                <Button color="primary" onClick={handleSubmit}>
                                                      Submit
                                                </Button>
                                          </ModalFooter>
                                    </Col>
                              </Row>
                        </Form>
                  </ModalBody>
            </Modal>
      )
}

export default SportsModal