import {
    ADD_CONTINENT_FAIL,
    ADD_CONTINENT_SUCCESS,
    ADD_NEW_CONTINENT,
    DELETE_CONTINENT,
    DELETE_CONTINENT_FAIL,
    DELETE_CONTINENT_SUCCESS,
    EDIT_CONTINENT,
    GET_CONTINENT,
    GET_CONTINENT_FAIL,
    GET_CONTINENT_SUCCESS,
} from "./actionTypes";

export const getContinent = () => ({
    type: GET_CONTINENT,
});

export const getContinentSuccess = (Continent) => ({
    type: GET_CONTINENT_SUCCESS,
    payload: Continent,
});

export const getContinentFail = (error) => ({
    type: GET_CONTINENT_FAIL,
    payload: error,
});

export const addContinent = (event) => ({
    type: ADD_NEW_CONTINENT,
    payload: event,
});

export const addContinentSuccess = (event) => {
    return {
        type: ADD_CONTINENT_SUCCESS,
        payload: event,
    };
};

export const addContinentFail = (event) => ({
    type: ADD_CONTINENT_FAIL,
    payload: event,
});


export const editContinent = (event, id) => ({
    type: EDIT_CONTINENT,
    payload: { event, id },
});

export const deleteContinent = (event) => ({
    type: DELETE_CONTINENT,
    payload: event,
});

export const deleteContinentSuccess = (event) => ({
    type: DELETE_CONTINENT_SUCCESS,
    payload: event,
});

export const deleteContinentFail = (error) => ({
    type: DELETE_CONTINENT_FAIL,
    payload: error,
});
