import { GET_SPORT, LIST_SPORT, ADD_SPORT, EDIT_SPORT, DELETE_SPORT, GET_SPORT_BY_Id, LIST_SPORT_BY_Id } from "./actionTypes";

export const getSport = () => ({
    type: GET_SPORT,
});

export const listSport = (event) => ({
    type: LIST_SPORT,
    payload: event,
});

export const getSportById = (id) => ({
    type: GET_SPORT_BY_Id,
    payload: id,
});

export const listSportById = (event) => ({
    type: LIST_SPORT_BY_Id,
    payload: event,
});

export const addSport = (event) => ({
    type: ADD_SPORT,
    payload: event,
});

export const editSport = (event, id) => ({
    type: EDIT_SPORT,
    payload: { event, id },
});

export const deleteSport = (event) => ({
    type: DELETE_SPORT,
    payload: event,
});
