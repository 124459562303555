import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Table,
} from "reactstrap";
import { bindActionCreators } from 'redux';
import { Link } from "react-router-dom";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import CategoryModal from "../../components/Modal/CategoryModal";
import * as Actions from "../../store/actions";
import DeleteModal from "../Tables/deleteModal";

const ArticleCategory = (props) => {
    const [breadcrumbItems] = useState([{ title: "Category", link: "#" }]);
    const [isModal, setIsModal] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        props.getPlayerCategory();
    }, []);

    const handleDeleteEvent = () => {
        props.deletePlayerCategory(selectedRow?._id || "");
        setIsDeleteModal(false);
        setSelectedRow(null);
    }

    const _onSubmitModal = (name, id) => {
        if (selectedRow) {
            props.editPlayerCategory({ name: name }, id);
        } else {
            props.addPlayerCategory({ name: name });
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Category" breadcrumbItems={breadcrumbItems} />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex  justify-content-between mb-2">
                                        <h4 className="card-title">Category List</h4>
                                        <Button onClick={() => setIsModal(true)} color="success" className="waves-effect waves-light me-1 float-end">
                                            Add New Category
                                        </Button>
                                    </div>
                                    <div className="table-responsive">
                                        <Table className="table table-bordered table-hover">
                                            <thead className="table-light table-nowrap">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Category Name</th>
                                                    <th style={{ width: "100px" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.playerCategory.map((e, i) => {
                                                    return (
                                                        <tr key={String(i)}>
                                                            <th scope="row">{i + 1}</th>
                                                            <td>{e?.name}</td>
                                                            <td>
                                                                <Link to="#" onClick={() => { setSelectedRow(e); setIsModal(true); }} className="me-3 text-primary">
                                                                    <i className="mdi mdi-pencil font-size-18"></i>
                                                                </Link>
                                                                <Link to="#" className="text-danger" onClick={() => { setSelectedRow(e); setIsDeleteModal(true); }}>
                                                                    <i className="mdi mdi-trash-can font-size-18"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {isModal &&
                <CategoryModal
                    showModal={isModal}
                    closeModal={() => { setSelectedRow(null); setIsModal(false) }}
                    item={selectedRow}
                    submitModal={(name, id) => _onSubmitModal(name, id)}
                    {...props}
                />
            }

            <DeleteModal show={isDeleteModal} onDeleteClick={handleDeleteEvent} onCloseClick={() => setIsDeleteModal(false)} />
        </React.Fragment>
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = ({ Player }) => {
    return {
        playerCategory: Player.playerCategory,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleCategory);